import style from "./PageWrapper.module.scss";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { Loader } from "../Loader/Loader";
import { Button, EButtonType } from "../Button/Button";

interface IPageWrapperProps {
  header?: React.ReactNode | string;
  onSave?: () => void;
  onNew?: () => void;
  onDelete?: () => void;
  children: any;
  state?: EAsyncState;
  actionBar?: React.ReactNode;
}

export const PageWrapper: React.FunctionComponent<IPageWrapperProps> = ({
  header,
  onNew,
  onSave,
  onDelete,
  children,
  state = EAsyncState.IDLE,
  actionBar,
}) => {
  if (state === EAsyncState.LOADING) {
    return (
      <div className={style.PageWrapper}>
        <Loader />
      </div>
    );
  }
  if (state === EAsyncState.FAILURE) {
    return (
      <div className={style.PageWrapper}>Произошла непредвиденная ошибка</div>
    );
  }
  return (
    <div className={style.PageWrapper}>
      <h3 className={style.PageHeader}>
        <span>{header || ""}</span>
        <div className={style.PageActionWrapper}>
          {!!actionBar ? actionBar : null}
          {onNew ? (
            <Button
              className={style.PageAction}
              onClick={onNew}
              text={"📰 Создать"}
            />
          ) : null}
          {onDelete ? (
            <Button
              className={style.PageAction}
              onClick={onDelete}
              styling={EButtonType.REJECT}
              text={"🗑️ Удалить"}
            />
          ) : null}
          {onSave ? (
            <Button
              className={style.PageAction}
              onClick={onSave}
              text={"💾 Сохранить"}
            />
          ) : null}
        </div>
      </h3>
      {children}
    </div>
  );
};
