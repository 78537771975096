import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { RootController } from "../RootController";
import { apiController } from "../ApiController";
import { makeAutoObservable, toJS } from "mobx";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { ITableOptions } from "../../components/Table/Table";
import { debounce, each, find, isArray, mergeWith } from "lodash";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";

export interface IDefaultTableOptions {
  filter?: any;
  sort?: any;
  autoFetch?: boolean;
  limit?: number;
}

function arrayMergeCustomizer(objValue, srcValue) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}

export class CommonTableController {
  root: RootController;
  state: EAsyncState = EAsyncState.LOADING;
  items: any[] = [];
  entityType: EEntityType;

  page: number = 0;
  limit: number = 20;
  count: number = 0;
  filter: any = {};
  filterValues: any = {};
  sort: any = {};
  defaultTableOptions: IDefaultTableOptions;
  selected: string[] = [];

  constructor(
    entityType: EEntityType,
    defaultTableOptions?: IDefaultTableOptions,
    defaultFilterValues?: any,
  ) {
    this.entityType = entityType;
    this.setDefaultTableOptions(defaultTableOptions);
    if (defaultFilterValues) {
      this.applyDefaultFilters(defaultFilterValues);
    }
    makeAutoObservable(this);
  }

  setDefaultTableOptions = (defaultTableOptions: IDefaultTableOptions) => {
    this.defaultTableOptions = {
      filter: {},
      sort: { $natural: -1 },
      autoFetch: true,
      limit: 20,
      ...(defaultTableOptions || {}),
    };
    this.limit = this.defaultTableOptions.limit
      ? this.defaultTableOptions.limit
      : 20;
  };

  applyDefaultFilters = (defaultFilters: any) => {
    this.filterValues = defaultFilters.filterValues;
    this.filter = mergeWith(this.filter, defaultFilters.filter);
    return this;
  };

  pagination = () => {
    return {
      pages: Math.ceil(this.count / this.limit),
      page: this.page,
      count: this.count,
    };
  };

  applyFilters = debounce((options: ITableOptions) => {
    this.filter = {};
    each(this.filterValues, (filterVal, filterName) => {
      const currentFilter = options.filters[filterName];
      if (!currentFilter.makeQuery) return;
      const filterQuery = currentFilter.makeQuery(filterVal, this.filterValues);
      this.filter = mergeWith(this.filter, filterQuery, arrayMergeCustomizer);
    });
    if (this.defaultTableOptions.autoFetch) {
      this.changePage(0);
    }
  }, 200);

  changePage = (newPage: number) => {
    this.page = newPage;
    this.loadTable().then();
  };

  onFilterChange = (
    options: ITableOptions,
    filterName: string,
    filterValue: any,
  ) => {
    this.filterValues[filterName] = filterValue;
    this.applyFilters(options);
  };

  onSortChange = (fieldName) => {
    switch (this.sort[fieldName]) {
      case -1:
        this.sort[fieldName] = 1;
        break;
      case 1:
        delete this.sort[fieldName];
        break;
      default:
        this.sort[fieldName] = -1;
    }
    this.changePage(0);
  };

  getItems = async () => {
    const filter = {
      ...this.defaultTableOptions.filter,
      ...(this.filter || {}),
    };
    const sort = { ...this.defaultTableOptions.sort, ...(this.sort || {}) };
    const resp = await apiController[this.entityType][ECrudMethods.LIST](
      filter,
      this.page,
      this.limit,
      sort,
    );
    this.items = resp.items;
    this.count = resp.count;
  };

  findByQuery = (q: any) => {
    return find(this.items, q);
  };

  findById = (id) => {
    return find(this.items, { _id: id });
  };

  loadTableSilent = async () => {
    try {
      await this.getItems();
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  loadTable = async () => {
    try {
      this.state = EAsyncState.LOADING;
      await this.getItems();
      this.state = EAsyncState.IDLE;
    } catch (e) {
      this.state = EAsyncState.FAILURE;
    }
  };

  onSelectionChange = (entityId) => {
    if (entityId === "all") {
      if (this.selected.length > 0) {
        this.selected = [];
      } else {
        this.selected = this.items.map((item) => item._id + "");
      }
      return;
    }
    if (this.selected.includes(entityId)) {
      this.selected = this.selected.filter((id) => id !== entityId);
    } else {
      this.selected.push(entityId);
    }
  };
}
