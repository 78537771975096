import { IMatchSelection } from "./textProcessor";

export enum ECustomPattern {
  Phone = "{phone}",
  Email = "{email}",
  AtTag = "{@tag}",
  Link = "{link}",
  TgLink = "{tglink}",
  Any = "{any}",
}

export const ALL_PATTERNS = new Set([...Object.values(ECustomPattern)]);

const getMatches = (input: string, rx: any): IMatchSelection[] => {
  let match;
  const out: IMatchSelection[] = [];
  while ((match = rx.exec(input)) !== null) {
    const url = match[0]; // The matched URL
    const startIndex = match.index;
    out.push({ startIndex, endIndex: startIndex + (url.length - 1) });
  }
  return out;
};

export type ICustomPatternsOccurances = { [k: string]: IMatchSelection[] };

const mobilePhoneRegex = /\b\d[\d\s]{6,14}\d\b/g;
const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
const tagRegex = /@\w{1,30}/g;
const urlRegex = /https?:\/\/[^\s/$.?#].[^\s]*/g;
const tgRegex = /https:\/\/t\.me\/\w{1,30}/g;

export const findCustomPatterns = (
  input: string,
  patterns: ECustomPattern[],
): ICustomPatternsOccurances => {
  const out: any = {};
  for (const ptr of patterns) {
    switch (ptr) {
      case ECustomPattern.Phone: {
        out[ptr] = getMatches(input, mobilePhoneRegex);
        break;
      }
      case ECustomPattern.Email: {
        out[ptr] = getMatches(input, emailRegex);
        break;
      }
      case ECustomPattern.AtTag: {
        out[ptr] = getMatches(input, tagRegex);
        break;
      }
      case ECustomPattern.Link: {
        out[ptr] = getMatches(input, urlRegex);
        break;
      }
      case ECustomPattern.TgLink: {
        out[ptr] = getMatches(input, tgRegex);
        break;
      }
      case ECustomPattern.Any: {
        out[ptr] = [{ startIndex: 0, endIndex: input.length - 1 }];
        break;
      }
    }
  }
  return out;
};
