import { makeAutoObservable, toJS } from "mobx";
import { cloneDeep, set } from "lodash";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { NEW_ITEM_ID } from "../../../_shared/Constants/ItemConstants";
import { apiController } from "../ApiController";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";
import { prompter } from "./PromptController";

export class CommonEntityController<T> {
  state: EAsyncState = EAsyncState.LOADING;
  item: any = null;
  newItem: T;
  entityType: EEntityType;
  isCardOpened: boolean = false;
  cardOptions: any;

  constructor(entityType: EEntityType, newItem?: T) {
    if (newItem) {
      this.newItem = newItem;
    }
    this.entityType = entityType;
    this.cardOptions = null;
    makeAutoObservable(this);
  }

  initItem = async (entityId: string | undefined) => {
    this.state = EAsyncState.LOADING;
    if (!!entityId) {
      return this.loadItem(entityId);
    }
    this.state = EAsyncState.IDLE;
    this.item = cloneDeep(this.newItem);
  };

  reset = () => {
    this.item = null;
  };

  loadItem = async (id: string) => {
    try {
      this.state = EAsyncState.LOADING;
      const result =
        await apiController[this.entityType][ECrudMethods.GETBY_ID](id);
      this.item = result.entity;
      this.state = EAsyncState.IDLE;
    } catch (e) {
      this.state = EAsyncState.FAILURE;
    }
  };

  loadByQuery = async (query: any) => {
    try {
      this.state = EAsyncState.LOADING;
      const result =
        await apiController[this.entityType][ECrudMethods.GET_BY_QUERY](query);
      this.item = result.entity;
      this.state = EAsyncState.IDLE;
    } catch (e) {
      this.state = EAsyncState.FAILURE;
    }
  };

  changeItem = (path: string) => async (newValue: any) => {
    if (!this.item) return;
    set(this.item, path, newValue);
  };

  saveItem = async () => {
    if (!this.item) return;
    try {
      this.state = EAsyncState.LOADING;
      const itemData = toJS(this.item);
      const res = await apiController[this.entityType][ECrudMethods.UPDATE]({
        ...itemData,
        _id: itemData._id === NEW_ITEM_ID ? undefined : itemData._id,
      });
      this.item = res.entity;
      this.state = EAsyncState.IDLE;
      if (!!this.cardOptions?.onSave) {
        this.cardOptions.onSave(this.item);
      }
      return res.entity;
    } catch (e) {
      this.state = EAsyncState.FAILURE;
    }
  };

  deleteItem = async () => {
    if (!this.item || !this.item._id) return false;
    if (
      !(await prompter.slider(
        `Вы уверены что хотите удалить айтем ${this.item.title || this.item.name}`,
      ))
    )
      return false;
    try {
      this.state = EAsyncState.LOADING;
      await apiController[this.entityType][ECrudMethods.DELETE](this.item._id);
      this.state = EAsyncState.IDLE;
      return true;
    } catch (e) {
      this.state = EAsyncState.FAILURE;
      return false;
    }
  };

  openCard = async (elementId: string, options?: any) => {
    if (this.item?._id + "" !== elementId + "") {
      await this.loadItem(elementId + "");
    }
    this.cardOptions = options;
    this.isCardOpened = true;
  };

  closeCard = () => {
    this.isCardOpened = false;
  };
}
