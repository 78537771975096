import React from "react";
import "./App.scss";
import { Header } from "./components/Header/Header";
import { Outlet } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { root } from "./controllers/RootController";
import { Sidebar } from "./components/Sidebar/Sidebar";
import { Helmet } from "react-helmet";
import { ClientModal } from "./modals/ClientModal/ClientModal";
import { EAsyncState } from "../_shared/_enums/EAsyncState";
import { PromptModal } from "./modals/PromptModal/PromptModal";

export const Layout = observer((props: any) => {
  if (root.state === EAsyncState.LOADING) {
    return <span>Loading</span>;
  }
  const unreadCounter = root.unreadsController.unreadCounter;
  return (
    <div className="App">
      <Helmet>
        <title>
          Leadder Admin{" "}
          {unreadCounter > 0 ? `(${unreadCounter}) new messages!` : ""}
        </title>
      </Helmet>
      <Header />

      <div className={"LayoutContent"}>
        <Sidebar />
        <div className={"LayoutScreen"}>
          <Outlet />
          <ClientModal controller={root.clientItemController} />
          <PromptModal />
        </div>
      </div>
    </div>
  );
});
