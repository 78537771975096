import * as petrovich from "petrovich";

export enum EDeclination {
  Nominative = "nominative",
  Genitive = "genitive",
  Dative = "dative",
  Accusative = "accusative",
  Instrumental = "instrumental",
  Prepositional = "prepositional",
}

export const declinate = (
  text,
  declination: EDeclination = EDeclination.Prepositional,
) => {
  return petrovich(
    {
      gender: "male",
      first: text,
    },
    declination,
  ).first;
};
