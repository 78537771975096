import style from "./SearchCoreItem.module.scss";
import { Modal } from "../../../components/Modal/Modal";
import React, { useState } from "react";
import { Button, EButtonType } from "../../../components/Button/Button";
import { GridRow } from "../../../components/Grid/GridRow";
import { Form } from "../../../components/Form/Form";
import { observer } from "mobx-react-lite";
import { TextArea } from "../../../components/Input/Input";
import cx from "classnames";
import { ConditionalController } from "../../../controllers/_common/ConditionalController";
import {
  IMatchSelection,
  NO_SELECTION,
  linkNumber,
  nextSpaceIndex,
  prepareTextForProcessing,
} from "../../../../_shared/Util/textProcessor";
import { EValidationReason } from "../../../../_shared/_enums/EValidationReason";
import { map } from "lodash";

const HighlightPosition = ({
  text,
  positions,
  reason,
}: {
  text: string;
  positions: IMatchSelection[];
  reason: EValidationReason;
}) => {
  const [highlighted, setHighlighted] = useState(-1);

  if (!positions.length) return <span>{text}</span>;
  if (positions[0].startIndex === -1) return <span>{text}</span>;

  return (
    <div className={style.previewResults}>
      <div className={style.previewWrapper}>
        <div className={style.previewFiller}>
          <span>{text}</span>
        </div>
        {map(positions, (pos, i) => {
          const before = text.slice(0, pos.startIndex);
          if (!pos.endIndex || pos.endIndex === -1)
            pos.endIndex = nextSpaceIndex(text, pos.startIndex);
          const marker = text.slice(pos.startIndex, pos.endIndex + 1);
          const afterPart = text.slice(pos.endIndex + 1, text.length).trim();
          return (
            <div className={style.previewVariant} key={i}>
              <span>
                <span className={style.invis}>{before}</span>
                <span
                  className={cx({
                    [style.marker]: true,
                    [style.markerHighlighted]: i === highlighted,
                    [style[reason]]: true,
                  })}
                  style={{ zIndex: 1000 - marker.length }}
                >
                  {marker}
                  <span className={style.occuranceNumber}>{i + 1}</span>
                </span>
                <span className={style.invis}> {afterPart}</span>
              </span>
            </div>
          );
        })}
      </div>
      <div className={style.previewSelections}>
        {map(positions, (pos, i) => {
          if (!pos.endIndex || pos.endIndex === -1)
            pos.endIndex = nextSpaceIndex(text, pos.startIndex);
          const marker = text.slice(pos.startIndex, pos.endIndex + 1);
          return (
            <div
              className={style.previewVariant}
              key={i}
              onMouseOver={() => {
                setHighlighted(i);
              }}
            >
              {i + 1}. -<span>{marker}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const TestingModal: React.FunctionComponent<{
  controller: ConditionalController;
}> = observer(({ controller }) => {
  if (!controller) return null;
  const isModalOpen = !!controller.testing.opened;
  if (!isModalOpen) return null;

  const { positions, reason }: any = controller.testing.result || {
    reason: EValidationReason.NO_LIST,
    positions: [NO_SELECTION],
  };

  const handleChangeTestText = (e: any) => {
    controller.testTextChange(e.target.value);
  };
  const { max_length, max_links } = controller.getCore();
  const goodTextLen = max_length > controller.testing.text.length;
  const linkCount = linkNumber(controller.testing.text);
  const goodLinks = max_links > linkCount;
  const preparedText = prepareTextForProcessing(controller.testing.text);

  return (
    <Modal
      header={"Тестирование ядра"}
      onClose={() => controller.closeTesting()}
    >
      <Form>
        <GridRow>
          <TextArea
            value={controller.testing.text}
            onChange={handleChangeTestText}
          />
        </GridRow>
        <GridRow>
          <HighlightPosition
            text={preparedText}
            positions={positions}
            reason={reason}
          />
        </GridRow>
        <GridRow direction={"RIGHT"}>
          <div className={style.bottomWrapper}>
            <div>
              <span
                className={cx({
                  [style.TestResult]: true,
                  [goodTextLen ? style.SUCCESS : style.ERROR]: true,
                })}
              >
                Символов: {controller.testing.text.length}
              </span>
              <span
                className={cx({
                  [style.TestResult]: true,
                  [goodLinks ? style.SUCCESS : style.ERROR]: true,
                })}
              >
                Ссылок: {linkCount}
              </span>

              <span
                className={cx({
                  [style.TestResult]: true,
                  [reason === EValidationReason.WHITE_LIST
                    ? style.SUCCESS
                    : style.ERROR]: true,
                })}
              >
                {reason === EValidationReason.WHITE_LIST
                  ? `🤑 Проходит [${positions.length} вхождения]`
                  : `⛔ Не проходит по тексту [${reason.toUpperCase()} : ${positions.length}`}
              </span>
            </div>
            <Button
              styling={EButtonType.DEFAULT}
              text={"Проверка AI"}
              onClick={controller.testCoreAi}
            />
            <Button
              styling={EButtonType.DEFAULT}
              text={"Проверка"}
              onClick={controller.testCore}
            />
          </div>
        </GridRow>
      </Form>
    </Modal>
  );
});
