import { Modal } from "../../../components/Modal/Modal";
import React, { useCallback } from "react";
import { Button, EButtonType } from "../../../components/Button/Button";
import { GridRow } from "../../../components/Grid/GridRow";
import { Form } from "../../../components/Form/Form";
import { observer } from "mobx-react-lite";
import { TargetListItemController } from "../../../controllers/TargetListItemController/TargetListItemController";
import { EGridItemSize, GridItem } from "../../../components/Grid/GridItem";
import { useDropzone } from "react-dropzone";
import style from "./TargetList.module.scss";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { Loader } from "../../../components/Loader/Loader";
import { EEntityType } from "../../../../_shared/_enums/EEntityType";
import { AsyncInput } from "../../../components/AsyncInput/AsyncInput";

export const AddTargetDumpModal: React.FunctionComponent<{
  controller: TargetListItemController;
}> = observer(({ controller }) => {
  const isModalOpen = !!controller.addingDumps.isOpened;

  const handleStartProcessingDump = async () => {
    const item = controller.entityController.item;
    await controller.startProcessingDump(item._id);
  };

  const onDrop = useCallback((acceptedFiles) => {
    controller.uploadDumps(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const status = controller.addingDumps.status;
  if (!isModalOpen) return null;

  const files = controller.addingDumps.files;

  if (status !== EAsyncState.IDLE) {
    return (
      <Modal
        header={"Добавление дампов из чатов"}
        onClose={() => controller.closeAddingDumps()}
      >
        <Loader />
      </Modal>
    );
  }

  return (
    <Modal
      header={"Добавление дампов из чатов"}
      onClose={() => controller.closeAddingDumps()}
    >
      <Form>
        <GridRow>
          <div className={style.dropZoneWrapper} {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>DRAG OR CLICK TO SELECT</p>
            )}
            <div className={style.dropZoneFileHolder}>
              {files.map((file, index) => {
                return <label key={index}>{file.name}</label>;
              })}
            </div>
          </div>
        </GridRow>
        <GridRow>
          <label>Искать сообщения по ядру</label>
        </GridRow>
        <GridRow>
          <GridItem size={EGridItemSize.full}>
            <AsyncInput
              value={controller.addingDumps.searchCoreId}
              entityType={EEntityType.SEARCH_CORE}
              onChange={controller.changeSearchCore}
            />
          </GridItem>
        </GridRow>
        <GridRow direction={"RIGHT"}>
          <Button
            styling={EButtonType.DEFAULT}
            text={"Добавить"}
            onClick={handleStartProcessingDump}
          />
        </GridRow>
      </Form>
    </Modal>
  );
});
