import React from "react";
import { observer } from "mobx-react-lite";
import style from "./BotEditor.module.scss";
import {
  IBox,
  ICoords,
} from "../../../_shared/_interfaces/botEditor/botEditor";

export const SelectionBox: React.FunctionComponent<{
  box?: { a: ICoords; b: ICoords };
}> = observer(({ box }) => {
  if (!box) return null;
  const left = box.a.x < box.b.x ? box.a.x : box.b.x;
  const top = box.a.y < box.b.y ? box.a.y : box.b.y;
  const width = Math.abs(box.a.x - box.b.x);
  const height = Math.abs(box.a.y - box.b.y);

  return (
    <div className={style.selectionBox} style={{ top, left, width, height }} />
  );
});
