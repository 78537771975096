import { observer } from "mobx-react-lite";
import { GridItem } from "../../../../components/Grid/GridItem";
import { Form } from "../../../../components/Form/Form";
import { CLIENT_STATUSES } from "../../../../../_shared/Constants/ClientStatuses";
import { BotElementItemController } from "../../../../controllers/BotScript/BotElementItemController";
import { GridRow } from "../../../../components/Grid/GridRow";
import { ComboboxInput } from "../../../../components/ComboboxInput/ComboboxInput";

export const BotElementStatusChangeForm: React.FunctionComponent<{
  controller: BotElementItemController;
}> = observer(({ controller }) => {
  const item = controller.entityController.item;
  return (
    <Form>
      <GridRow>
        <GridItem>
          <ComboboxInput
            options={CLIENT_STATUSES}
            value={item.element_data.new_status}
            onChange={(newVal) =>
              controller.entityController.changeItem("element_data.new_status")(
                newVal,
              )
            }
          />
        </GridItem>
      </GridRow>
    </Form>
  );
});
