import { RootController } from "../RootController";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { makeAutoObservable, toJS } from "mobx";
import { apiController } from "../ApiController";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";
import { EClientStatus } from "../../../_shared/_enums/EClientStatus";
import { ECurrency } from "../../../_shared/_enums/ECurrency";
import { DateTime, Duration } from "luxon";
import { ITransactionSchema } from "../../../_shared/Database/transaction";
import { each, get, reduce } from "lodash";
import { currencyController } from "../_common/CurrencyController";

const calculateTxSum = (txs: ITransactionSchema[]) => {
  let sum = 0;
  let count = 0;
  each(txs, (tx) => {
    let val = tx.usd_value;
    if (!val) {
      const multiplier = currencyController.getMulti(tx.currency);
      val = tx.value * multiplier;
    }
    if (val !== 0) {
      count = count + 1;
    }
    sum = sum + val;
  });
  return { sum, count };
};

export class ProfitReportController {
  root: RootController;
  state: EAsyncState = EAsyncState.IDLE;

  isBaseStatsLoaded: any;

  // Settings
  timeFrom: number = 0;
  timeTo: number = 0;
  selectedScript: any = null;
  selectedLocation: any = null;

  // Results
  periodResults: any = { main: {}, prev: {} };
  baseResults: any = {};

  constructor(root: RootController) {
    this.root = root;
    this.timeFrom = DateTime.now().minus({ month: 1 }).toMillis();
    this.timeTo = DateTime.now().toMillis();
    makeAutoObservable(this);
  }

  changeScript = (newScript) => {
    this.selectedScript = newScript;
    this.loadReport();
    this.loadBaseStats();
  };

  changeLocation = (newLocation) => {
    this.selectedLocation = newLocation;
    this.loadReport();
    this.loadBaseStats();
  };

  traversePeriod = (side: "prev" | "next") => () => {
    const diffPeriodMs = this.timeTo - this.timeFrom;
    const op = side === "prev" ? "minus" : "plus";
    this.timeTo = DateTime.fromMillis(this.timeTo)
      [op]({ milliseconds: diffPeriodMs })
      .toMillis();
    this.timeFrom = DateTime.fromMillis(this.timeFrom)
      [op]({ milliseconds: diffPeriodMs })
      .toMillis();
    this.loadReport();
  };

  changeDate = (dateType) => (newDate) => {
    if (dateType)
      this[dateType] = DateTime.fromISO(newDate)
        [dateType === "timeFrom" ? "startOf" : "endOf"]("day")
        .toMillis();

    this.loadReport();
  };

  getScriptQuery = () => {
    let scriptQuery: any = this.selectedScript
      ? {
          bot_control_script_id: this.selectedScript,
        }
      : {};

    scriptQuery = this.selectedLocation
      ? {
          ...scriptQuery,
          location_ids: { $in: [this.selectedLocation] },
        }
      : scriptQuery;
    return scriptQuery;
  };

  loadPeriodStats = async (from, to, prefix) => {
    const fromLabel = DateTime.fromMillis(from).toLocaleString(
      DateTime.DATE_SHORT,
    );
    const toLabel = DateTime.fromMillis(to).toLocaleString(DateTime.DATE_SHORT);
    this.periodResults[prefix]["label"] = `с ${fromLabel} по ${toLabel}`;

    const scriptQuery = this.getScriptQuery();

    const aggrResp = await apiController[EEntityType.CLIENT][
      ECrudMethods.AGGREGATE
    ]([
      {
        $match: {
          status_history: {
            $elemMatch: {
              $or: [
                {
                  status: "LOST",
                },
                {
                  status: "CLOSED",
                },
              ],
              date: {
                $gte: from,
                $lt: to,
              },
            },
          },
          ...scriptQuery,
        },
      },
      {
        $project: {
          first_name: 1,
          lostDate: {
            $filter: {
              input: "$status_history",
              as: "status",
              cond: {
                $and: [
                  {
                    $eq: ["$$status.status", "LOST"],
                  },
                  {
                    $gte: ["$$status.date", from],
                  },
                  {
                    $lt: ["$$status.date", to],
                  },
                ],
              },
            },
          },
          closedDate: {
            $filter: {
              input: "$status_history",
              as: "status",
              cond: {
                $and: [
                  {
                    $eq: ["$$status.status", "CLOSED"],
                  },
                  {
                    $gte: ["$$status.date", from],
                  },
                  {
                    $lt: ["$$status.date", to],
                  },
                ],
              },
            },
          },
        },
      },
      {
        $project: {
          first_name: 1,
          lostDate: {
            $first: "$lostDate",
          },
          closedDate: {
            $first: "$closedDate",
          },
        },
      },
      {
        $match: {
          $expr: {
            $or: [
              {
                $gt: ["$lostDate.date", "$closedDate.date"],
              },
              {
                $eq: ["$closedDate", null],
              },
            ],
          },
        },
      },
      {
        $count: "count",
      },
    ]);

    this.periodResults[prefix]["lostClients"] = get(aggrResp, "res.0.count", 0);

    const respNewClients = await apiController[EEntityType.CLIENT][
      ECrudMethods.COUNT
    ]({
      $and: [
        {
          status_history: {
            $elemMatch: {
              status: EClientStatus.TRIAL,
              date: {
                $gt: DateTime.fromMillis(from).minus({ days: 5 }).toMillis(),
                $lt: to,
              },
            },
          },
        },
        {
          status_history: {
            $elemMatch: {
              status: EClientStatus.CLOSED,
              date: {
                $gt: from,
                $lt: to,
              },
            },
          },
        },
      ],
      ...scriptQuery,
    });

    this.periodResults[prefix]["newClients"] = respNewClients.count;

    const respTesting = await apiController[EEntityType.CLIENT][
      ECrudMethods.COUNT
    ]({
      status_history: {
        $elemMatch: {
          status: EClientStatus.TRIAL,
          date: {
            $gt: from,
            $lt: to,
          },
        },
      },
      ...scriptQuery,
    });
    this.periodResults[prefix]["testingClients"] = respTesting.count;

    const allLeadMessages = await apiController[EEntityType.CLIENT][
      ECrudMethods.COUNT
    ]({
      status_history: {
        $elemMatch: {
          status: { $ne: EClientStatus.IN_PROCESS },
          date: {
            $gt: from,
            $lt: to,
          },
        },
      },
      ...scriptQuery,
    });
    this.periodResults[prefix]["allLeadMessages"] = allLeadMessages.count;

    const allTxResp = await apiController[EEntityType.TRANSACTION][
      ECrudMethods.LIST
    ](
      {
        value: { $gt: 0 },
        created_at: { $gt: from, $lt: to },
      },
      0,
      100000,
    );

    const currentTxClientIds = reduce(
      allTxResp.items,
      (acc, tx) => {
        acc.push(tx.client_id);
        return acc;
      },
      [],
    );

    const respClosed = await apiController[EEntityType.CLIENT][
      ECrudMethods.LIST
    ](
      {
        _id: { $in: currentTxClientIds },
        ...scriptQuery,
      },
      0,
      100000,
    );

    this.periodResults[prefix]["closedClients"] = respClosed.count;
    const closedByIds = respClosed.items.map((c) => c._id + "");

    let txxs = allTxResp.items;

    if (this.selectedScript) {
      const transactionsByScript = await apiController[EEntityType.TRANSACTION][
        ECrudMethods.LIST
      ](
        {
          value: { $gt: 0 },
          client_id: { $in: closedByIds },
          created_at: { $gt: from, $lt: to },
        },
        0,
        100000,
      );
      txxs = transactionsByScript.items;
    }

    const txAggr = calculateTxSum(txxs);

    this.periodResults[prefix]["totalTxProfit"] = txAggr.sum;
    this.periodResults[prefix]["totalTxCount"] = txAggr.count;
    this.periodResults[prefix]["totalTxAvgProfit"] =
      this.periodResults[prefix]["totalTxProfit"] /
      this.periodResults[prefix]["totalTxCount"];

    this.periodResults[prefix]["testConversionRate"] =
      this.periodResults[prefix]["testingClients"] /
      this.periodResults[prefix]["allLeadMessages"];

    this.periodResults[prefix]["paymentConversionRate"] =
      this.periodResults[prefix]["closedClients"] /
      this.periodResults[prefix]["allLeadMessages"];
  };

  loadPeriodDiffStats = async () => {
    const prevFrom = this.timeFrom - (this.timeTo - this.timeFrom);

    await this.loadPeriodStats(this.timeFrom, this.timeTo, "main");
    await this.loadPeriodStats(prevFrom, this.timeFrom, "prev");
  };

  loadBaseStats = async () => {
    let scriptQuery = this.getScriptQuery();

    const allInProcessClientsResp = await apiController[EEntityType.CLIENT][
      ECrudMethods.COUNT
    ]({
      status: EClientStatus.IN_PROCESS,
      ...scriptQuery,
    });
    this.baseResults["allInProcessClients"] = allInProcessClientsResp.count;

    const allTestingClientsResp = await apiController[EEntityType.CLIENT][
      ECrudMethods.COUNT
    ]({
      status: EClientStatus.TRIAL,
      ...scriptQuery,
    });
    this.baseResults["allTestingClients"] = allTestingClientsResp.count;

    const allPayedClientsResp = await apiController[EEntityType.CLIENT][
      ECrudMethods.COUNT
    ]({
      status: EClientStatus.CLOSED,
      ...scriptQuery,
    });
    this.baseResults["allPayedClients"] = allPayedClientsResp.count;

    const currentMonthEnd = DateTime.now().endOf("month").toMillis();

    const clientsWillPayThisMonthResp = await apiController[EEntityType.CLIENT][
      ECrudMethods.COUNT
    ]({
      status: EClientStatus.CLOSED,
      payed_to: {
        $gt: Date.now(),
        $lt: currentMonthEnd,
      },
      ...scriptQuery,
    });
    this.baseResults["clientsWillPay"] = clientsWillPayThisMonthResp.count;

    const currentDate = DateTime.now().toMillis();
    const nextMonth = DateTime.now().plus({ month: 1 }).toMillis();

    const clientsWillPayNextMonthResp = await apiController[EEntityType.CLIENT][
      ECrudMethods.COUNT
    ]({
      status: EClientStatus.CLOSED,
      payed_to: {
        $gt: currentDate,
        $lt: nextMonth,
      },
      ...scriptQuery,
    });
    this.baseResults["unrealizedNextMonthTxCount"] =
      clientsWillPayNextMonthResp.count;
  };

  loadReport = async () => {
    this.state = EAsyncState.LOADING;
    if (!this.isBaseStatsLoaded) {
      await this.loadBaseStats();
      this.isBaseStatsLoaded = true;
    }
    await this.loadPeriodDiffStats();
    this.state = EAsyncState.IDLE;
  };
}
