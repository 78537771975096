import { RootController } from "../RootController";
import { makeAutoObservable } from "mobx";
import { NEW_ITEM_ID } from "../../../_shared/Constants/ItemConstants";
import { CommonEntityController } from "../_common/CommonEntityController";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { ILeadSource } from "../../../_shared/Database/leadSource";
import { ESourceType } from "../../../_shared/_enums/ESourceType";

const newItem: ILeadSource = {
  _id: NEW_ITEM_ID,
  title: "",
  source_id: "",
  username: "string",
  source_type: ESourceType.TELEGRAM,
  tg_node_phone: 0 + "",
};

export class LeadSourceItemController {
  root: RootController;
  entityController: CommonEntityController<ILeadSource>;

  constructor(root: RootController) {
    this.root = root;
    this.entityController = new CommonEntityController<ILeadSource>(
      EEntityType.LEAD_SOURCE,
      newItem,
    );
    makeAutoObservable(this);
  }

  changeLocations = (newLocations: any) => {
    this.entityController.changeItem("location_ids")(
      newLocations.map((loc) => loc._id),
    );
  };
}
