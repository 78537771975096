import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { root } from "../../../controllers/RootController";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { Loader } from "../../../components/Loader/Loader";
import { FormInput } from "../../../components/FormInput/FormInput";
import { observer } from "mobx-react-lite";
import { Form, FormInputWrapper } from "../../../components/Form/Form";
import { EGridItemSize, GridItem } from "../../../components/Grid/GridItem";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { GridRow } from "../../../components/Grid/GridRow";
import {
  CURRENCIES,
  CURRENCIES_KEYED,
} from "../../../../_shared/Constants/Currencies";
import { TextArea } from "../../../components/Input/Input";
import { ComboboxInput } from "../../../components/ComboboxInput/ComboboxInput";

interface ICategoryItemProps {}

export const TransactionItem: React.FunctionComponent<ICategoryItemProps> =
  observer((props) => {
    let params = useParams();
    let navigate = useNavigate();
    const { transactionItemId } = params;
    const entityController = root.transactionItemController.entityController;
    useEffect(() => {
      root.transactionItemController.initItem(transactionItemId).then();
    }, []);
    if (entityController.state !== EAsyncState.IDLE) return <Loader />;
    const item = entityController.item;
    if (!item) return null;
    return (
      <PageWrapper
        header={
          "Перевод клиенту " +
          item.client_id +
          " " +
          item.value +
          " " +
          item.currency
        }
        onSave={async () => {
          const newItem = await entityController.saveItem();
          navigate(`/transactions/${newItem._id}`);
        }}
        onDelete={
          transactionItemId
            ? async () => {
                const result = await entityController.deleteItem();
                if (result) {
                  navigate(`/transactions`);
                }
              }
            : undefined
        }
        state={EAsyncState.IDLE}
      >
        <Form>
          <GridRow>
            <GridItem>
              <Link to={"/clients/" + item.client_id}>Ссылка на клиента</Link>
            </GridItem>
          </GridRow>
          <GridRow>
            <GridItem>
              <FormInput
                path={"value"}
                item={item}
                title={"Сумма"}
                onChange={entityController.changeItem("value")}
              />
            </GridItem>
            <GridItem>
              <label>Валюта</label>
              <ComboboxInput
                options={CURRENCIES}
                value={item.currency}
                onChange={(newVal) =>
                  entityController.changeItem("currency")(newVal)
                }
              />
            </GridItem>
            <GridItem>
              <FormInput
                path={"usd_value"}
                item={item}
                title={"Сумма USD"}
                onChange={entityController.changeItem("usd_value")}
              />
            </GridItem>
          </GridRow>
          <GridRow>
            <GridItem size={EGridItemSize.half}>
              <FormInput
                path={"tx_id"}
                item={item}
                title={"Tx ID"}
                onChange={entityController.changeItem("tx_id")}
              />
            </GridItem>
          </GridRow>{" "}
          <GridRow>
            <GridItem size={EGridItemSize.half}>
              <FormInputWrapper>
                <label>Комментарий</label>
                <TextArea
                  value={entityController.item.comment}
                  onChange={(e) =>
                    entityController.changeItem("comment")(e.target.value)
                  }
                />
              </FormInputWrapper>
            </GridItem>
          </GridRow>
        </Form>
      </PageWrapper>
    );
  });
