import { RootController } from "../RootController";
import { makeAutoObservable } from "mobx";
import { NEW_ITEM_ID } from "../../../_shared/Constants/ItemConstants";
import { INode } from "../../../_shared/Database/node";
import { CommonEntityController } from "../_common/CommonEntityController";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { ETgNodeType } from "../../../_shared/_enums/ETgNodeType";
import { apiController } from "../ApiController";
import { CommonTableController } from "../_common/CommonTableController";
import { makeToast } from "../../utils/toast";

const newItem: INode = {
  _id: NEW_ITEM_ID,
  api_hash: "",
  api_id: 0,
  max_new_dialogs: 10,
  phone: "",
  proxyServer: null,
  error: null,
  node_type: ETgNodeType.MESSAGING,
};

export class NodeItemController {
  root: RootController;
  entityController: CommonEntityController<INode>;
  logsTableController: CommonTableController;
  isManagementConsoleOpen: boolean;
  newAvatar?: File;

  newProfile: { firstName: string; lastName: string; about: string } = {
    firstName: "",
    lastName: "",
    about: "",
  };

  new2FA: { currentPassword: string; newPassword: string } = {
    currentPassword: "",
    newPassword: "",
  };

  constructor(root: RootController) {
    this.root = root;
    this.entityController = new CommonEntityController<INode>(
      EEntityType.NODE,
      newItem,
    );
    this.logsTableController = new CommonTableController(EEntityType.NODE_LOG);
    makeAutoObservable(this);
  }

  initItem = async (nodeId: string) => {
    await this.entityController.initItem(nodeId);
    this.logsTableController = new CommonTableController(EEntityType.NODE_LOG, {
      filter: {
        node_id: nodeId,
      },
      sort: {
        $natural: -1,
      },
      autoFetch: true,
    });
    await this.logsTableController.loadTable();
  };

  initConsoleData = () => {
    const node: INode = this.entityController.item;
    const nodeUser = node.me;
    if (!nodeUser) {
      throw new Error("cant load node data");
    }
  };

  restartNode = async (node: INode) => {
    await apiController.nodes.restartNode(node.phone);
    await this.entityController.initItem(node._id + "");
    makeToast("Нода успешно перезагружена");
  };

  openManagement = () => {
    const node: INode = this.entityController.item;
    const nodeUser = node.me;
    if (!nodeUser) {
      makeToast("Node is not initted");
      return;
    }
    this.initConsoleData();
    this.isManagementConsoleOpen = true;
  };

  closeManagement = () => {
    this.isManagementConsoleOpen = false;
  };

  changeProfile = (change) => {
    this.newProfile = { ...this.newProfile, ...change };
  };

  saveName = () => {
    this.manage("changeProfile")(this.newProfile);
  };

  change2FA = (change) => {
    this.new2FA = { ...this.new2FA, ...change };
  };

  save2FA = () => {
    this.manage("change2FA")(this.new2FA);
  };

  terminateAllOtherSessions = () => {
    this.manage("terminateAllOtherSessions")({});
  };

  setFullPrivacy = () => {
    this.manage("setFullPrivacy")({});
  };

  uploadAvatar = (files) => {
    this.newAvatar = files[0];
  };

  saveAvatar = async () => {
    const node: INode = this.entityController.item;
    try {
      const res = await apiController.nodeManager.uploadAvatar(
        node.phone,
        this.newAvatar,
      );
      if (res.error !== "") {
        throw new Error(res.error);
      }
      makeToast("Avatar is set!");
    } catch (e) {
      makeToast(e.toString());
    }
  };

  manage = (command: string) => async (payload) => {
    const node: INode = this.entityController.item;
    try {
      const res = await apiController.nodeManager.manage(
        node.phone,
        command,
        payload,
      );
      if (res.error !== "") {
        throw new Error(res.error);
      }
      if (!!res.result) {
        makeToast("Ok!");
        return;
      }
      makeToast("Something went wrong...");
    } catch (e) {
      makeToast(e.toString());
    }
  };
}
