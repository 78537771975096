import style from "./FeedEditor.module.scss";
import React from "react";
import { identity, map } from "lodash";
import { observer } from "mobx-react-lite";
import { IFeed } from "../../../_shared/Database/feed";
import { ISearchCore } from "../../../_shared/Database/searchCore";
import { Link } from "react-router-dom";
import { UnreadMessages } from "../ChatMessenger/UnreadMessages";
import { root } from "../../controllers/RootController";
import { Button } from "../Button/Button";
import { PayedToLabel } from "../PayedToLabel/PayedToLabel";
import { Input, LabeledInput, TextArea } from "../Input/Input";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { AsyncInput } from "../AsyncInput/AsyncInput";

interface IFeedsEditor {
  onChangeLocationIds: (feedId: string, newLocationIds: string[]) => void;
  onChangeCategoryIds: (feedId: string, newCategoryIds: string[]) => void;
  feeds: { [key: string]: IFeed };
  searchCores: { [key: string]: ISearchCore };
  onChangeBlacklistWords: (feedId: string, newWords: string) => void;
  onOpenPaymentModal: (feedId: string) => void;
  onOpenPaymentHistoryModal: (feedId: string) => void;
  onChangeMuteState: (feedId: string, newMute: boolean) => void;
}

export const FeedEditor: React.FunctionComponent<IFeedsEditor> = observer(
  ({
    feeds,
    onChangeBlacklistWords,
    onChangeMuteState,
    onChangeLocationIds,
    onChangeCategoryIds,
    onOpenPaymentModal,
    onOpenPaymentHistoryModal,
    searchCores,
  }) => {
    return (
      <div className={style.FeedsEditor}>
        {map(
          feeds,
          ({
            _id,
            bot_project_id,
            group_title = "",
            location_ids,
            search_core_ids,
            unread_messages = 0,
            is_chat_blocked = false,
            payed_to,
            blacklist_words,
            is_muted,
          }) => {
            return (
              <div key={_id} className={style.FeedWrapper}>
                <div className={style.FeedEntitySelector}>
                  <div className={style.FeedEntityId}>
                    {_id} - {bot_project_id}
                  </div>
                  <div className={style.FeedEntityName}>
                    {group_title ? group_title : "Личный чат"}{" "}
                    {is_chat_blocked ? "(блокировка)" : ""}
                  </div>
                  <Link
                    className={style.FeedToChat}
                    to={`/chat/${_id}`}
                    onClick={(e) => root.clientItemController.closeCard()}
                  >
                    В Чат <UnreadMessages msgCount={unread_messages} />
                  </Link>
                  {root.isRoot() && (
                    <div className={style.PayedTo}>
                      <label>Оплачено до</label>
                      <div>
                        <PayedToLabel val={payed_to} />
                      </div>
                      <div className={style.PayedTo}>
                        <Button
                          text={"Оплата"}
                          onClick={() => onOpenPaymentModal(_id)}
                        />
                        <Button
                          text={"История оплат"}
                          onClick={() => onOpenPaymentHistoryModal(_id)}
                        />
                      </div>
                      <div className={style.PayedTo}>
                        <LabeledInput
                          title="Is muted"
                          type="checkbox"
                          onValueChange={(v) => onChangeMuteState(_id, v)}
                          value={+is_muted}
                          isEnabled
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className={style.FeedEntitySelector}>
                  <div className={style.FeedEntityTitle}>
                    <span>Места</span>
                  </div>
                  <AsyncInput
                    value={location_ids}
                    entityType={EEntityType.LOCATION}
                    onChange={(newVal) =>
                      onChangeLocationIds(_id, newVal as string[])
                    }
                    isMulti
                  />
                </div>
                <div className={style.FeedEntitySelector}>
                  <div className={style.FeedEntityTitle}>
                    <span>Ядра</span>
                  </div>
                  <AsyncInput
                    value={search_core_ids}
                    entityType={EEntityType.SEARCH_CORE}
                    onChange={(newVal) =>
                      onChangeCategoryIds(_id, newVal as string[])
                    }
                    isMulti
                  />
                </div>
                <div className={style.FeedEntitySelector}>
                  <div className={style.FeedEntityTitle}>
                    <span>Минус-слова</span>
                  </div>
                  <TextArea
                    value={blacklist_words}
                    onChange={(e) =>
                      onChangeBlacklistWords(_id, e.target.value)
                    }
                  />
                </div>
              </div>
            );
          },
        )}
      </div>
    );
  },
);
