import React, { useEffect } from "react";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { root } from "../../../controllers/RootController";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  ControlledTable,
  ITableOptions,
} from "../../../components/Table/Table";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import {
  TG_NODE_TYPES,
  TG_NODE_TYPES_KEYED,
} from "../../../../_shared/Constants/TgNodeTypes";
import { nodeName, printRestrictions } from "../_utils/nodeHelpers";
import { INode } from "../../../../_shared/Database/node";
import { Button } from "../../../components/Button/Button";
import { UploadTDatasModal } from "../NodeUploaderModal/NodeUploaderModal";
import { ComboboxInput } from "../../../components/ComboboxInput/ComboboxInput";
import { getDateStraight } from "../../../../_shared/Util/dates";

const tableOptions: ITableOptions = {
  filters: {
    name: {
      title: "Поиск",
      makeQuery: (val) => {
        const regex = { $regex: val, $options: "i" };
        return {
          $and: [
            {
              $or: [
                { phone: regex },
                { error: regex },
                { comment: regex },
                { "me.firstName": regex },
                { "me.lastName": regex },
                { "proxyServer.server": regex },
              ],
            },
          ],
        };
      },
      type: "string",
    },
    nodeType: {
      title: "Тип ноды",
      makeQuery: (nodeType) => {
        if (!nodeType) return {};
        return {
          $and: [
            {
              node_type: nodeType,
            },
          ],
        };
      },
      type: "custom",
      customRender: (t, onChange) => {
        return (
          <ComboboxInput
            options={TG_NODE_TYPES}
            value={t}
            onChange={onChange}
          />
        );
      },
    },
    hasError: {
      title: "Has error",
      makeQuery: (val) => {
        if (!val) return {};
        return {
          $and: [
            {
              error: { $ne: null },
            },
          ],
        };
      },
      type: "checkbox",
    },
  },
  columns: [
    {
      title: "ID",
      field: "_id",
      format: (val: string) => {
        return "..." + val.slice(val.length - 6);
      },
    },
    {
      title: "Огр-я",
      field: "me.restricted",
      format: (val: string, item: INode) => {
        return printRestrictions(item, false);
      },
    },
    {
      title: "PHONE",
      field: "phone",
    },
    {
      title: "Name",
      field: "me.firstName",
      format: (val: string, item: any) => {
        if (!item.me) return "";
        return nodeName(item);
      },
    },
    {
      title: "TYPE",
      field: "node_type",
      format: (val: string) => {
        return TG_NODE_TYPES_KEYED[val]?.label || "";
      },
    },
    {
      title: "Лимит рассылок",
      field: "max_new_dialogs",
    },
    {
      title: "Proxy",
      field: "proxyServer.server",
    },
    {
      title: "COMMENT",
      field: "comment",
    },
    {
      title: "ERROR",
      field: "error",
    },
    {
      title: "Активирован в",
      field: "last_inited_at",
      columnStyle: { width: "150px" },
      format: (val) => {
        if (!val) return null;
        return getDateStraight(val);
      },
    },
  ],
};

export const NodeTable = observer(() => {
  let navigate = useNavigate();
  const controller = root.nodeTableController;
  useEffect(() => {
    controller.init().then();
  }, []);
  return (
    <PageWrapper
      header={"Ноды"}
      state={EAsyncState.IDLE}
      onNew={() => navigate("/nodes/new")}
      actionBar={
        <div>
          <Button
            text={"Загрузка аккаунтов"}
            onClick={() => root.nodeUploadController.accountsUploadModalOpen()}
          />
          <UploadTDatasModal controller={root.nodeUploadController} />
        </div>
      }
    >
      <div>
        Оставшиеся лимиты по рассылкам {controller.unusedLimits} из{" "}
        {controller.maxLimits}
      </div>
      <ControlledTable
        controller={controller.tableController}
        options={tableOptions}
        getItemHref={(item) => `/nodes/${item._id}`}
      />
    </PageWrapper>
  );
});
