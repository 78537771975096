import { observer } from "mobx-react-lite";
import { ClientItemController } from "../../controllers/Client/ClientItemController";
import { Modal } from "../../components/Modal/Modal";
import { trimText } from "../../../_shared/Util/text";
import { Button, EButtonType } from "../../components/Button/Button";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { Loader } from "../../components/Loader/Loader";
import style from "./ClientModal.module.scss";
import { Link } from "react-router-dom";
import { generateUserLink } from "../../components/Util/Username";
import { ClientForm } from "../../components/ClientForm/ClientForm";
import { root } from "../../controllers/RootController";

export const ClientModal: React.FunctionComponent<{
  controller: ClientItemController;
}> = observer(({ controller }) => {
  if (!controller.isCardOpened) return null;
  const openFullPage = (e) => {
    controller.closeCard();
  };
  return (
    <Modal
      header={
        <span>
          {trimText(controller.getCardName(), 30)}{" "}
          {generateUserLink(controller.getUsername())}
        </span>
      }
      footer={
        <div className={style.footerActions}>
          <div></div>
          <div>
            <Link to={`/clients/${controller.item._id}`} onClick={openFullPage}>
              Открыть полностью
            </Link>
            <Button text={"Сохранить"} onClick={controller.saveItem} />
          </div>
        </div>
      }
      onClose={controller.closeCard}
    >
      {controller.state === EAsyncState.LOADING ? (
        <Loader />
      ) : (
        <ClientForm controller={controller} />
      )}
    </Modal>
  );
});
