import { makeAutoObservable, toJS } from "mobx";
import { ClientItemController } from "./ClientItemController";
import { ITransaction } from "../../../_shared/Database/transaction";
import { apiController } from "../ApiController";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";
import { cloneDeep, first } from "lodash";
import { CommonEntityController } from "../_common/CommonEntityController";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { NEW_ITEM_ID } from "../../../_shared/Constants/ItemConstants";
import { ECurrency } from "../../../_shared/_enums/ECurrency";
import { makeToast } from "../../utils/toast";
import { CommonTableController } from "../_common/CommonTableController";
import { currencyController } from "../_common/CurrencyController";

const newItem: ITransaction = {
  _id: NEW_ITEM_ID,
  client_id: "",
  created_at: 0,
  is_income: true,
  payment_target: {
    type: "feed",
  },
  value: 29,
  usd_value: 29,
  currency: ECurrency.USD,
};

export class PaymentController {
  client: ClientItemController;
  isHistoryOpened: boolean = false;
  isModalOpened: boolean = false;
  transactionsTableController: CommonTableController;
  status: EAsyncState = EAsyncState.IDLE;
  entity: CommonEntityController<ITransaction>;
  feedId: string = "";
  isMessageNeeded: boolean = true;

  constructor(client: ClientItemController) {
    this.client = client;
    this.entity = new CommonEntityController(EEntityType.TRANSACTION, newItem);
    this.transactionsTableController = new CommonTableController(
      EEntityType.TRANSACTION,
    );
    makeAutoObservable(this);
  }

  loadForm = async () => {
    try {
      this.status = EAsyncState.LOADING;
      const client = this.client.item;

      this.transactionsTableController = new CommonTableController(
        EEntityType.TRANSACTION,
        {
          filter: {
            client_id: client._id + "",
            "payment_target.id": this.feedId,
          },
        },
      );
      await this.transactionsTableController.loadTable();
      const transactions = this.transactionsTableController.items;
      const firstTrans = first(transactions);
      if (!firstTrans) {
        const value = newItem.value;
        this.entity.item = cloneDeep({
          ...newItem,
          created_at: Date.now(),
          value: value,
          client_id: client._id,
          currency: value > 100 ? ECurrency.RUB : ECurrency.USD,
          payment_target: {
            type: "feed",
            id: this.feedId,
            payed_to: Date.now(),
          },
        });
        return;
      }
      const { entity } = await apiController[EEntityType.TRANSACTION][
        ECrudMethods.GETBY_ID
      ](firstTrans._id);
      this.entity.item = {
        ...entity,
        created_at: Date.now(),
        _id: undefined,
        comment: undefined,
        payment_target: {
          type: "feed",
          id: this.feedId,
          payed_to: entity.payment_target.payed_to
            ? entity.payment_target.payed_to
            : Date.now(),
        },
      };
    } catch (e) {
      this.status = EAsyncState.FAILURE;
    }
  };

  openModal = async (feedId: string) => {
    this.feedId = feedId;
    await this.loadForm();
    this.isModalOpened = true;
    try {
      await currencyController.updateRates();
    } catch (e) {
      console.error(e);
    }
  };

  closeModal = () => {
    this.transactionsTableController.items = [];
    this.isModalOpened = false;
  };

  changeIsMessageNeeded = (newVal) => {
    this.isMessageNeeded = newVal;
  };

  registerPayment = async () => {
    const paymentInfo = toJS(this.entity.item);
    const needSendMessage = this.isMessageNeeded;
    try {
      this.status = EAsyncState.LOADING;
      await apiController.clientUtils.registerTransaction(
        needSendMessage,
        paymentInfo,
      );
      this.status = EAsyncState.IDLE;
      this.isModalOpened = false;
      await this.client.loadItem(this.client.item._id);
      makeToast("Успешно продлено");
    } catch (e) {
      makeToast(e.toString());
      this.status = EAsyncState.FAILURE;
    }
  };

  changeCurrency = (newCurr) => {
    this.entity.item.currency = newCurr;
    this.changeValue(this.entity.item.usd_value, true);
  };

  changeValue = (newValue, isUSD = false) => {
    const currency = this.entity.item.currency || ECurrency.USD;

    if (isUSD) {
      this.entity.item.usd_value = newValue;
      const converted = currencyController.usdToVal(
        parseFloat(newValue + ""),
        currency,
      );
      this.entity.item.value = Math.round(converted);
    } else {
      this.entity.item.value = newValue;
      const converted = currencyController.valToUsd(
        parseFloat(newValue + ""),
        currency,
      );
      this.entity.item.usd_value = Math.round(converted);
    }
  };

  openHistoryModal = async (feedId: string) => {
    this.feedId = feedId;
    this.isHistoryOpened = true;
    try {
      const client = this.client.item;
      this.status = EAsyncState.LOADING;
      this.transactionsTableController = new CommonTableController(
        EEntityType.TRANSACTION,
        {
          filter: {
            client_id: client._id + "",
            "payment_target.id": this.feedId,
          },
        },
      );
      await this.transactionsTableController.loadTable();
    } catch (e) {
      this.status = EAsyncState.FAILURE;
    }
  };

  closeHistoryModal = () => {
    this.feedId = null;
    this.isHistoryOpened = false;
  };
}
