import React, { InputHTMLAttributes, TextareaHTMLAttributes } from "react";
import style from "./Input.module.scss";
import cx from "classnames";

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  onValueChange?: (newVal: any) => void;
  isEnabled?: boolean;
};

type ILabeledInputProps = InputProps & {
  title: string;
};

export const Input: React.FunctionComponent<InputProps> = (props) => {
  if (props.type === "checkbox") {
    return (
      <input
        type={props.type}
        disabled={!props.isEnabled}
        className={cx(style.Input, props.className)}
        checked={!!props.value}
        onChange={(e) => {
          props.onValueChange(e.target.checked);
        }}
      />
    );
  }
  return (
    <input
      type={props.type}
      disabled={!props.isEnabled}
      value={props.value || ""}
      className={cx(style.Input, props.className)}
      autoFocus={props.autoFocus || false}
      onCopy={(e) => {
        e.stopPropagation();
      }}
      onPaste={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onKeyUp={(e) => {
        e.stopPropagation();
      }}
      onChange={(e) => {
        props.onValueChange(e.target.value);
      }}
    />
  );
};

export const LabeledInput: React.FunctionComponent<ILabeledInputProps> = (
  props,
) => {
  return (
    <label className={cx(style.LabeledInput, props.className)}>
      <Input {...props} />
      <span className={cx(style.LabeledInputTitle)}>{props.title}</span>
    </label>
  );
};

type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  onValueChange?: (newVal: any) => void;
  onChangeRaw?: (e: any) => void;
  isEnabled?: boolean;
};

export const TextArea: React.FunctionComponent<TextAreaProps> = (props) => {
  const isEnabled = props.isEnabled === undefined ? true : props.isEnabled;
  return (
    <textarea
      className={cx(style.Input, style.TextArea, props.className)}
      autoFocus={props.autoFocus || false}
      onCopy={(e) => {
        e.stopPropagation();
      }}
      onPaste={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onKeyUp={(e) => {
        e.stopPropagation();
      }}
      disabled={!isEnabled}
      onChange={(e) => {
        if (props.onValueChange) {
          props.onValueChange(e.target.value);
        }
        if (props.onChange) {
          props.onChange(e);
        }
        if (props.onChangeRaw) {
          props.onChangeRaw(e);
        }
      }}
      value={props.value}
      {...props}
    />
  );
};
