import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { root } from "../../../controllers/RootController";
import {
  ControlledTable,
  ITableOptions,
  Table,
} from "../../../components/Table/Table";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { Button } from "../../../components/Button/Button";
import { AsyncInput } from "../../../components/AsyncInput/AsyncInput";
import { EEntityType } from "../../../../_shared/_enums/EEntityType";
import { Modal } from "../../../components/Modal/Modal";

const tableOptions: ITableOptions = {
  filters: {
    search: {
      title: "Поиск",
      makeQuery: (val) => {
        const regex = { $regex: val, $options: "i" };
        return { title: regex };
      },
      type: "string",
    },
    core: {
      title: "Ядро",
      makeQuery: () => {
        return {};
      },
      type: "custom",
      customRender: (core, onChange) => {
        return (
          <AsyncInput
            value={core ? core._id : null}
            entityType={EEntityType.SEARCH_CORE}
            onChangeRaw={onChange}
          />
        );
      },
    },
    scanningSize: {
      title: " Размер анализа",
      type: "number",
    },
    scanning: {
      title: " Анализировать",
      type: "custom",
      customRender: () => {
        return (
          <div style={{ marginTop: "5px" }}>
            <Button
              text={root.semanticsController.getScanStatus()}
              onClick={root.semanticsController.analyze}
            />
          </div>
        );
      },
    },
  },
  columns: [
    {
      title: "Текст",
      field: "text",
      format: (val: string) => {
        return <span>{val}</span>;
      },
    },
    {
      title: "Вхождения",
      field: "messageIds",
      format: (val: any) => {
        return <span>{val.length || 0}</span>;
      },
    },
  ],
};

export const SemanticsSampleModal = observer(() => {
  if (!root.semanticsController.isSamplesModalOpened) return null;
  if (root.semanticsController.samplesLoadingState === EAsyncState.LOADING) {
    return (
      <Modal
        header={"Сэмплы сообщений"}
        innerStyle={{ minWidth: 800 }}
        onClose={root.semanticsController.closeSamplesModal}
      >
        Loading...
      </Modal>
    );
  }

  return (
    <Modal
      header={
        "Сэмплы сообщений со словом " +
        root.semanticsController.currentSampleItem.text
      }
      innerStyle={{ minWidth: 800, maxWidth: 800 }}
      onClose={root.semanticsController.closeSamplesModal}
    >
      <Table
        options={{
          filters: {},
          columns: [
            {
              title: "Текст",
              field: "text",
              format: (val: string) => {
                return <span>{val}</span>;
              },
            },
          ],
        }}
        items={root.semanticsController.loadedSamples}
      />
    </Modal>
  );
});

export const Semantics = observer(() => {
  useEffect(() => {
    root.semanticsController.init().then();
  }, []);
  return (
    <PageWrapper header={"Семантический анализ"} state={EAsyncState.IDLE}>
      <ControlledTable
        controller={root.semanticsController.tableController}
        options={tableOptions}
        onItemClick={(item) => {
          root.semanticsController.openSamples(item);
        }}
      />

      <SemanticsSampleModal />
    </PageWrapper>
  );
});
