import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { root } from "../../../controllers/RootController";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { Loader } from "../../../components/Loader/Loader";
import { FormInput } from "../../../components/FormInput/FormInput";
import { observer } from "mobx-react-lite";
import { Form } from "../../../components/Form/Form";
import { EGridItemSize, GridItem } from "../../../components/Grid/GridItem";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { GridRow } from "../../../components/Grid/GridRow";
import {
  TG_NODE_TYPES,
  TG_NODE_TYPES_KEYED,
} from "../../../../_shared/Constants/TgNodeTypes";
import { nodeName, printRestrictions } from "../_utils/nodeHelpers";
import { NodeLogTable } from "../../../components/NodeLogTable/NodeLogTable";
import { Button } from "../../../components/Button/Button";
import { prompter } from "../../../controllers/_common/PromptController";
import { ComboboxInput } from "../../../components/ComboboxInput/ComboboxInput";
import { NodeManagementModal } from "./NodeManagementModal";

interface INodeItemProps {}

export const NodeItem: React.FunctionComponent<INodeItemProps> = observer(
  (props) => {
    let params = useParams();
    let navigate = useNavigate();
    const { nodeId } = params;
    const controller = root.nodeItemController;
    const entityController = root.nodeItemController.entityController;
    useEffect(() => {
      controller.initItem(nodeId).then();
    }, []);
    if (entityController.state !== EAsyncState.IDLE) return <Loader />;
    const item = entityController.item;

    return (
      <PageWrapper
        header={
          nodeId
            ? `${item.phone} ${nodeName(entityController.item)}`
            : `Новая Нода`
        }
        actionBar={
          <>
            <Button
              text="Управление"
              onClick={() => root.nodeItemController.openManagement()}
            />
            {nodeId ? (
              <Button
                text={"Перезагрузка ноды"}
                onClick={() =>
                  root.nodeItemController.restartNode(entityController.item)
                }
              />
            ) : null}
          </>
        }
        onSave={async () => {
          if (entityController.item.reserved) {
            await prompter.alert("Невозможно сохранить зарезервированную ноду");
            return;
          }
          const newItem = await entityController.saveItem();
          navigate(`/nodes/${newItem._id}`);
        }}
        onDelete={
          nodeId
            ? async () => {
                if (entityController.item.reserved) {
                  await prompter.alert(
                    "Невозможно удалить зарезервированную ноду",
                  );
                  return;
                }
                const result = await entityController.deleteItem();
                if (result) {
                  navigate(`/nodes`);
                }
              }
            : undefined
        }
        state={EAsyncState.IDLE}
      >
        <Form>
          <GridRow>
            <GridItem>
              <FormInput
                path={"phone"}
                item={item}
                title={"Телефон"}
                isEnabled={false}
                onChange={entityController.changeItem("phone")}
              />
            </GridItem>
            <GridItem>
              <label>Тип ноды</label>
              <ComboboxInput
                options={TG_NODE_TYPES}
                value={item.node_type}
                onChange={(newVal) =>
                  entityController.changeItem("node_type")(newVal)
                }
              />
            </GridItem>
          </GridRow>
          <h3>Tg Api</h3>
          <GridRow>
            <GridItem>
              <FormInput
                path={"api_id"}
                item={item}
                title={"Api Id"}
                onChange={entityController.changeItem("api_id")}
              />
            </GridItem>
            <GridItem>
              <FormInput
                path={"api_hash"}
                item={item}
                title={"Api Hash"}
                onChange={entityController.changeItem("api_hash")}
              />
            </GridItem>
          </GridRow>
          <h3>Proxy</h3>
          <GridRow>
            <GridItem>
              <FormInput
                path={"proxyServer.server"}
                item={item}
                title={"Proxy Server"}
                onChange={entityController.changeItem("proxyServer.server")}
              />
            </GridItem>
            <GridItem>
              <FormInput
                path={"proxyServer.port"}
                item={item}
                title={"Proxy Port"}
                onChange={entityController.changeItem("proxyServer.port")}
              />
            </GridItem>
            <GridItem>
              <FormInput
                path={"proxyServer.username"}
                item={item}
                title={"Proxy username"}
                onChange={entityController.changeItem("proxyServer.username")}
              />
            </GridItem>
            <GridItem>
              <FormInput
                path={"proxyServer.password"}
                item={item}
                title={"Proxy password"}
                onChange={entityController.changeItem("proxyServer.password")}
              />
            </GridItem>
          </GridRow>
          <h3>Info</h3>
          <GridRow>
            <GridItem size={EGridItemSize.half}>
              <FormInput
                path={"comment"}
                item={item}
                isEnabled={true}
                title={"Comment"}
                onChange={entityController.changeItem("comment")}
              />
            </GridItem>
            <GridItem size={EGridItemSize.half}>
              <FormInput
                path={"error"}
                item={item}
                isEnabled={false}
                title={"Node error"}
                onChange={entityController.changeItem("error")}
              />
            </GridItem>
          </GridRow>
          <h3>Limits</h3>
          <GridRow>
            <GridItem>
              <FormInput
                path={"max_new_dialogs"}
                item={item}
                title={"New Dialogs daily limit"}
                onChange={entityController.changeItem("max_new_dialogs")}
              />
            </GridItem>
            <GridItem size={EGridItemSize.full}>
              <label>Ограничения</label>
              <pre>{printRestrictions(entityController.item)}</pre>
            </GridItem>
          </GridRow>
          <h3>Logs</h3>
          <GridRow>
            <NodeLogTable tableController={controller.logsTableController} />
          </GridRow>
        </Form>
        <NodeManagementModal controller={controller} />
      </PageWrapper>
    );
  },
);
