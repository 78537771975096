import React from "react";
import "./index.scss";
import { Layout } from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { ClientTable } from "./pages/client/ClientTable/ClientTable";
import { SearchCoreTable } from "./pages/searchCore/SearchCoreTable/SearchCoreTable";
import { SearchCoreItem } from "./pages/searchCore/SearchCoreItem/SearchCoreItem";
import { observer } from "mobx-react-lite";
import { LeadSourceItem } from "./pages/leadSource/LeadSourceItem/LeadSourceItem";
import { LeadSourceTable } from "./pages/leadSource/LeadSourceTable/LeadSourceTable";
import { ClientItem } from "./pages/client/ClientItem/ClientItem";
import { LocationTable } from "./pages/location/LocationTable/LocationTable";
import { LocationItem } from "./pages/location/LocationItem/LocationItem";
import { CategoryTable } from "./pages/category/CategoryTable/CategoryTable";
import { CategoryItem } from "./pages/category/CategoryItem/CategoryItem";
import { TargetListTable } from "./pages/targetList/TargetListTable/TargetListTable";
import { TargetListItem } from "./pages/targetList/TargetListItem/TargetListItem";
import { FeedChat } from "./pages/feedChat/FeedChat";
import { SettingsLinkItem } from "./pages/settingsLink/SettingsLinkItem/SettingsLinkItem";
import { SettingsLinkTable } from "./pages/settingsLink/SettingsLinkTable/SettingsLinkTable";
import { EChatMode } from "../_shared/_enums/EChatMode";
import { NodeTable } from "./pages/node/NodeTable/NodeTable";
import { NodeItem } from "./pages/node/NodeItem/NodeItem";
import { MessageLogTable } from "./pages/messageLog/MessageLogTable/MessageLogTable";
import { Semantics } from "./pages/semantics/SemanticsTable/SemanticsTable";
import { ProfitReport } from "./pages/reports/Profit/ProfitReport";
import { BotElementItem } from "./pages/botElements/BotElementItem/BotElementItem";
import { UnreadsTable } from "./pages/unreads/UnreadsTable/UnreadsTable";
import { DialogsTable } from "./pages/dialog/DialogTable";
import { BotScriptItem } from "./pages/botScripts/BotScriptItem/BotScriptItem";
import { BotScriptTable } from "./pages/botScripts/BotScriptTable/BotScriptTable";
import { DialogMessagesTable } from "./pages/dialog/DialogMessagesTable";
import { TransactionTable } from "./pages/transaction/TransactionTable/TransactionTable";
import { TransactionItem } from "./pages/transaction/TransactionItem/TransactionItem";
import { NodeAnalyticsReport } from "./pages/reports/Analytics/NodeAnalyticsChart";
import { SpamScheduleTable } from "./pages/spamSchedule/SpamScheduleTable";

export const ObservedRouter = observer(() => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />

          <Route path="clients" element={<ClientTable />} />
          <Route path="clients/:clientId" element={<ClientItem />} />

          <Route path="spamSchedule" element={<SpamScheduleTable />} />

          <Route path="searchCores" element={<SearchCoreTable />} />
          <Route
            path="searchCores/:searchCoreId"
            element={<SearchCoreItem />}
          />
          <Route path="searchCores/new" element={<SearchCoreItem />} />

          <Route path="leadSources" element={<LeadSourceTable />} />
          <Route
            path="leadSources/:leadSourceId"
            element={<LeadSourceItem />}
          />
          <Route path="leadSources/new" element={<LeadSourceItem />} />

          <Route path="locations" element={<LocationTable />} />
          <Route path="locations/:locationId" element={<LocationItem />} />
          <Route path="locations/new" element={<LocationItem />} />

          <Route path="categories" element={<CategoryTable />} />
          <Route path="categories/:categoryId" element={<CategoryItem />} />
          <Route path="categories/new" element={<CategoryItem />} />

          <Route path="targetLists" element={<TargetListTable />} />
          <Route
            path="targetLists/:targetListId"
            element={<TargetListItem />}
          />
          <Route path="targetLists/new" element={<TargetListItem />} />

          <Route path="nodes" element={<NodeTable />} />
          <Route path="nodes/:nodeId" element={<NodeItem />} />
          <Route path="nodes/new" element={<NodeItem />} />

          {/* CHATS */}
          <Route path="dialogMessages" element={<DialogMessagesTable />} />
          <Route path="dialogs" element={<DialogsTable />} />
          <Route path="unreads" element={<UnreadsTable />} />
          <Route
            path="chat/:chatId"
            element={<FeedChat mode={EChatMode.BOTCHAT} />}
          />

          {/* CHATTING (dialogs) */}
          <Route
            path="dialog/:chatId"
            element={<FeedChat mode={EChatMode.DIALOG} />}
          />

          <Route path="settingsLinks" element={<SettingsLinkTable />} />
          <Route
            path="settingsLinks/:settingsLinkId"
            element={<SettingsLinkItem />}
          />
          <Route path="settingsLinks/new" element={<SettingsLinkItem />} />

          <Route path="messageLog" element={<MessageLogTable />} />

          <Route path="semantics" element={<Semantics />} />

          <Route path="transactions" element={<TransactionTable />} />
          <Route
            path="transactions/:transactionItemId"
            element={<TransactionItem />}
          />

          {/* REPORTS */}
          <Route path="reports/profit" element={<ProfitReport />} />
          <Route
            path="reports/nodeAnalytics"
            element={<NodeAnalyticsReport />}
          />

          <Route path="botScripts/scripts" element={<BotScriptTable />} />
          <Route
            path="botScripts/scripts/:botScriptId"
            element={<BotScriptItem />}
          />
          <Route path="botScripts/scripts/new" element={<BotScriptItem />} />

          <Route
            path="botScripts/elements/:botElementId"
            element={<BotElementItem />}
          />
        </Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>404, OOPS</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
});
