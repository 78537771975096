import React from "react";
import { observer } from "mobx-react-lite";
import { map } from "lodash";
import { EBotVariableType } from "../../../_shared/_enums/EBotVariableType";
import { getDateStraight } from "../../../_shared/Util/dates";
import style from "./ClientForm.module.scss";
import { root } from "../../controllers/RootController";

const renderVarValue = (varType: EBotVariableType, value) => {
  switch (varType) {
    case EBotVariableType.DATE: {
      if (!value) {
        return "No date";
      }
      return getDateStraight(value);
    }
    case EBotVariableType.NUMBER:
    case EBotVariableType.STRING: {
      return value;
    }
  }
};

export const VariablesTable: React.FunctionComponent<{
  allVariables: any;
  botVariables: any;
}> = observer(({ allVariables, botVariables }) => {
  return (
    <table className={style.VarsTable}>
      <tbody>
        {map(botVariables, (value, varUniqId) => {
          const variable = allVariables[varUniqId];
          return (
            <tr key={varUniqId}>
              <td>{variable.title}:</td>
              <td
                onDoubleClick={() =>
                  root.clientItemController.changeVariable(varUniqId)
                }
              >
                {renderVarValue(variable.var_type, value)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});
