import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { root } from "../../../controllers/RootController";
import {
  ControlledTable,
  ITableOptions,
} from "../../../components/Table/Table";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { PayedToLabel } from "../../../components/PayedToLabel/PayedToLabel";
import { filter } from "lodash";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { getDateStraight } from "../../../../_shared/Util/dates";

const tableOptions: ITableOptions = {
  filters: {
    name: {
      title: "Поиск",
      makeQuery: (val) => {
        const regex = { $regex: val, $options: "i" };
        return {
          $and: [
            {
              $or: [{ title: regex }],
            },
          ],
        };
      },
      type: "string",
    },
  },
  columns: [
    {
      title: "ID",
      field: "_id",
      format: (val: string) => {
        return "..." + val.slice(val.length - 6);
      },
    },
    {
      title: "Название",
      field: "title",
    },
    {
      title: "Скрипт",
      field: "bot_script_id",
      format: (val: string) => {
        const script = root.botScriptTableController.findById(val);
        return !script ? "" : script.title;
      },
    },
    {
      title: "Ядра",
      field: "search_core_ids",
      format: (val: string[], item: any) => {
        const location_ids = filter(
          root.searchCoreTableController.items,
          (loc) => {
            if (!val) return false;
            return val.includes(loc._id + "");
          },
        );
        return location_ids
          .map((loc) => {
            return loc.title;
          })
          .join(" ,");
      },
    },
    {
      title: "Места",
      field: "location_ids",
      format: (val: string[], item: any) => {
        const location_ids = filter(
          root.locationTableController.items,
          (loc) => {
            if (!val) return false;
            return val.includes(loc._id + "");
          },
        );
        return location_ids
          .map((loc) => {
            return loc.title;
          })
          .join(" ,");
      },
    },
    {
      title: "Дата создания",
      field: "creation_date",
      format: (val: number) => {
        return getDateStraight(val);
      },
    },
  ],
};

export const SettingsLinkTable = observer(() => {
  let navigate = useNavigate();
  useEffect(() => {
    root.locationTableController
      .loadTable()
      .then(root.searchCoreTableController.loadTable)
      .then(root.settingsLinkTableController.loadTable)
      .then(root.botScriptTableController.loadTable);
  }, []);
  return (
    <PageWrapper
      header={"Настроечные ссылки"}
      state={EAsyncState.IDLE}
      onNew={() => navigate("/settingsLinks/new")}
    >
      <ControlledTable
        controller={root.settingsLinkTableController}
        options={tableOptions}
        getItemHref={(item) => `/settingsLinks/${item._id}`}
      />
    </PageWrapper>
  );
});
