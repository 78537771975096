import style from "./Sidebar.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { CustomBadge, UnreadMessages } from "../ChatMessenger/UnreadMessages";
import { root } from "../../controllers/RootController";
import { observer } from "mobx-react-lite";

const AdminSidebar = observer(() => {
  const navigate = useNavigate();
  const handleGotoUnreadClient = async () => {
    navigate("/unreads/");
  };
  return (
    <div className={style.Sidebar}>
      <div className={style.SidebarSection}>
        <div className={style.SidebarItem}>
          <Link to={"/clients"}>Клиенты</Link>
          <UnreadMessages
            msgCount={root.unreadsController.unreadCounter}
            onClick={handleGotoUnreadClient}
          />
        </div>
        <div className={style.SidebarItem}>
          <Link to={"/spamSchedule"}>
            Упр. Рассылкой
            <CustomBadge
              text={root.schedullingController.getCountersText()}
              isRed={
                root.schedullingController.unschedulledCount > 30 ||
                root.schedullingController.schedulledCount < 30
              }
            />
          </Link>
        </div>
        <div className={style.SidebarItem}>
          <Link to={"/dialogs"}>Диалоги</Link>
        </div>
        <div className={style.SidebarItem}>
          <Link to={"/dialogMessages"}>Сообщения диалогов</Link>
        </div>
        <div className={style.SidebarItem}>
          <Link to={"/searchCores"}>Ядра поиска</Link>
        </div>
        <div className={style.SidebarItem}>
          <Link to={"/leadSources"}>Источники</Link>
        </div>
        <div className={style.SidebarItem}>
          <Link to={"/categories"}>Категории</Link>
        </div>
        <div className={style.SidebarItem}>
          <Link to={"/locations"}>Места</Link>
        </div>
        <div className={style.SidebarItem}>
          <Link to={"/targetLists"}>Таргетинг</Link>
        </div>
        <div className={style.SidebarItem}>
          <Link to={"/settingsLinks"}>Ссылки</Link>
        </div>
        <div className={style.SidebarItem}>
          <Link to={"/transactions"}>Транзакции</Link>
        </div>
        <div className={style.SidebarItem}>
          <Link to={"/reports/profit"}>Отчет о доходах</Link>
        </div>
      </div>
      <div className={style.SidebarSection}>
        <div className={style.SidebarItem}>
          <Link to={"botScripts/scripts"}>Скрипты бота</Link>
        </div>
        <div className={style.SidebarItem}>
          <Link to={"/semantics"}>Семантика</Link>
        </div>
        <div className={style.SidebarItem}>
          <Link to={"/messageLog"}>Лаборатория</Link>
        </div>
        <div className={style.SidebarItem}>
          <Link to={"/nodes"}>Ноды</Link>
        </div>
      </div>
    </div>
  );
});

const ModerSidebar = () => {
  return (
    <div className={style.Sidebar}>
      <div className={style.SidebarSection}>
        <div className={style.SidebarItem}>
          <Link to={"/locations"}>Места</Link>
        </div>
        <div className={style.SidebarItem}>
          <Link to={"/targetLists"}>Таргетинг</Link>
        </div>
      </div>
    </div>
  );
};

export const Sidebar = observer(() => {
  return (
    <div className={style.SidebarWrapper}>
      <span className={style.userName}>
        {root.isRoot() ? "Админ" : "Модер"} - {root.me.first_name}{" "}
        {root.me.last_name}
      </span>
      {!root.isRoot() ? <ModerSidebar /> : <AdminSidebar />}
    </div>
  );
});
