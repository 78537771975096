import { ITgMsg } from "../_interfaces/telegram/ITg";
import { ESourceType } from "../_enums/ESourceType";
import { filter, identity } from "lodash";
import { ITransactionSchema } from "../Database/transaction";
import { DateTime } from "luxon";
import { ISearchCore } from "../Database/searchCore";
import { ILocation } from "../Database/location";

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, "g"), replace);
}

export const trimText = (text, val) => {
  if (text.length > val - 3) {
    return text.slice(0, val) + "...";
  }
  return text;
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const separateUsernameFromLink = (
  link: string,
  separator = "t.me/",
  secondSplitter = "/",
) => {
  const trimmed = link.trim();
  if (!trimmed.includes(separator)) return null;
  const seg = trimmed.split(separator)[1];
  if (!seg) return null;
  return seg.split(secondSplitter)[0];
};

export const parseSourceIdentifierFromLink = (
  link: string,
  sourceType: ESourceType,
) => {
  switch (sourceType) {
    case ESourceType.TELEGRAM:
      return separateUsernameFromLink(link, "t.me/") || null;
    case ESourceType.OK:
      return separateUsernameFromLink(link, "ok.ru/group/") || null;
    case ESourceType.FACEBOOK:
      // https://www.facebook.com/groups/241424002748/posts/10159343756992749/
      return separateUsernameFromLink(link, "www.facebook.com/groups/") || null;
    case ESourceType.VK:
      // https://vk.com/wall-164883506_90183
      const wallId = separateUsernameFromLink(link, "vk.com/wall-", "_");
      if (wallId && wallId.length) return wallId;
      // https://vk.com/topic-96917596_33584532?post=4578
      const topicId = separateUsernameFromLink(link, "vk.com/topic-", "_");
      if (topicId && topicId.length) return topicId;
      return null;
    //case ESourceType.TWITTER:
    //    return separateUsernameFromLink(link, 'twitter.com/') || null;
    //case ESourceType.INSTAGRAM:
    //    // У инстаграма ссылкой может быть только юзер
    //    // https://instagram.com/baraholka_prim25_region/
    //    return separateUsernameFromLink(link, 'instagram.com/') || null;
  }
};

export const formatUsername = (username) => {
  return username.toLowerCase().trim();
};

export const prepareLeadCard = ({
  forwardTo,
  chatTitle,
  messageText,
  messageLink,
  username,
  vars,
  name,
}) => {
  const fullName = username
    ? `<a href="https://t.me/${username}">${name}</a>`
    : name;
  const forwardToMessageLink = messageLink
    ? `<a href="${messageLink}">${chatTitle}</a>`
    : chatTitle;

  return {
    to: forwardTo,
    message: `
Имя: ${fullName} 
${vars.map((v) => `${v.title}: ${v.value}`).join("\n")}
=====
Сообщение в ${forwardToMessageLink}
${messageText}
 `.trim(),
    options: JSON.stringify({
      parse_mode: "HTML",
      disable_web_page_preview: true,
    }),
  };
};

export const prepareForwardMessage = ({
  forwardTo,
  chatTitle,
  messageText,
  messageLink,
  username,
  chatLink,
  locations,
  cores,
}: {
  forwardTo: number;
  chatTitle: string;
  messageText: string;
  messageLink: string;
  username: string;
  chatLink: string;
  locations: ILocation[];
  cores: ISearchCore[];
}) => {
  if (!username && !messageLink) return null;

  messageText = replaceAll(messageText, "<", "");
  messageText = replaceAll(messageText, ">", "");

  const searchCoresTags = cores
    .map((core) => (core.hashtag ? "#" + core.hashtag : ""))
    .join(" ");
  const possibleLocations = locations
    .map((l) =>
      l.title ? "#" + replaceAll(l.title.toLowerCase(), " ", "_") : "",
    )
    .join(" ");

  messageText =
    `<a href="https://t.me/${chatLink}">${chatTitle}</a>` +
    "\n\n" +
    trimText(messageText, 600) +
    "\n\n" +
    "Возможный регион: " +
    possibleLocations +
    "\n" +
    searchCoresTags;

  return {
    to: forwardTo,
    message: messageText,
    options: JSON.stringify({
      parse_mode: "HTML",
      disable_web_page_preview: true,
      reply_markup: JSON.stringify({
        inline_keyboard: [
          filter(
            [
              messageLink !== ""
                ? {
                    text: `Сообщение`,
                    url: messageLink,
                  }
                : undefined,
              username
                ? {
                    text: `Пользователь`,
                    url: `https://t.me/${username}`,
                  }
                : undefined,
            ],
            identity,
          ),
        ],
      }),
    }),
  };
};

export const prepareFeedPaymentMessage = (
  forwardTo,
  paymentInfo: ITransactionSchema,
) => {
  const payedTo = paymentInfo.payment_target.payed_to || 0;
  const relativeDate = DateTime.fromMillis(payedTo).toFormat("dd.MM.yyyy");
  return {
    to: forwardTo,
    message: `Бот был успешно продлен до ${relativeDate}`,
    isCustom: true,
  };
};

export const getMessageFromTgMsg = (tgMsg: ITgMsg) => {
  return (
    tgMsg?.content?.text?.text ||
    tgMsg?.content?.caption?.text ||
    tgMsg?.content?.emoji ||
    tgMsg?.content?.sticker?.emoji
  );
};

export const generateSettingsBotLink = (settingsLink) => {
  return `https://t.me/${process.env.REACT_APP_BOT_NICKNAME}?start=${settingsLink._id}`;
};

export const getSenderIdFromMessage = (tgMsg: ITgMsg) => {
  if (typeof tgMsg?.sender_id === "number") {
    return tgMsg?.sender_id;
  }
  return tgMsg?.sender_id?.user_id;
};

export const trimToMax = (source: string, maxLen: number) => {
  if (source.length < maxLen) return source;
  return source.slice(0, maxLen - 3) + "...";
};
