import { makeAutoObservable } from "mobx";
import { apiController } from "./ApiController";
import { SearchCoreItemController } from "./SearchCore/SearchCoreItemController";
import { ClientItemController } from "./Client/ClientItemController";
import { LocationItemController } from "./Location/LocationItemController";
import { LeadSourceItemController } from "./LeadSource/LeadSourceItemController";

import { CommonTableController } from "./_common/CommonTableController";
import { EEntityType } from "../../_shared/_enums/EEntityType";
import { CategoryItemController } from "./Category/CategoryItemController";
import { LeadSourceTableController } from "./LeadSource/LeadSourceTableController";
import { TargetListItemController } from "./TargetListItemController/TargetListItemController";
import { FeedChatController } from "./FeedChat/FeedChatController";
import { SettingsLinkItemController } from "./SettingsLinkItemController/SettingsLinkItemController";
import { EAsyncState } from "../../_shared/_enums/EAsyncState";
import { NodeItemController } from "./NodeController/NodeItemController";
import { MessageLogController } from "./MessageLog/MessageLogController";
import { SemanticController } from "./SemanticAnalysisController/SemanticAnalysisController";
import { NodeUploadController } from "./NodeUploaderController/NodeUploadController";
import { ProfitReportController } from "./Report/ProfitReportController";
import { BotElementItemController } from "./BotScript/BotElementItemController";
import { NodeTableController } from "./NodeController/NodeTableController";
import { UnreadsController } from "./UnreadsController/UnreadsController";
import { BotScriptItemController } from "./BotScript/BotScriptItemController";
import { BotEditorController } from "./BotScript/BotEditorController";
import { TransactionItemController } from "./Transaction/TransactionItemController";
import { NodeReportController } from "./Report/NodeReportController";
import { SchedullingController } from "./SchedullingController/SchedullingController";
import axios from "axios";

export class RootController {
  state: EAsyncState = EAsyncState.LOADING;
  me: any = null;

  feedChatController: FeedChatController;

  clientTableController: CommonTableController;
  schedullingController: SchedullingController;
  clientItemController: ClientItemController;

  searchCoreTableController: CommonTableController;
  searchCoreItemController: SearchCoreItemController;

  chatListTableController: CommonTableController;

  leadSourceTableController: LeadSourceTableController;
  leadSourceItemController: LeadSourceItemController;

  locationTableController: CommonTableController;
  locationItemController: LocationItemController;

  categoryTableController: CommonTableController;
  categoryItemController: CategoryItemController;

  targetListTableController: CommonTableController;
  targetListItemController: TargetListItemController;

  settingsLinkTableController: CommonTableController;
  settingsLinkItemController: SettingsLinkItemController;

  nodeTableController: NodeTableController;
  nodeItemController: NodeItemController;
  nodeUploadController: NodeUploadController;

  messageLogController: MessageLogController;

  semanticsController: SemanticController;

  transactionItemController: TransactionItemController;
  transactionTableController: CommonTableController;
  profitReportController: ProfitReportController;
  nodeReportController: NodeReportController;

  botElementTableController: CommonTableController;
  botElementItemController: BotElementItemController;
  botScriptTableController: CommonTableController;
  botScriptItemController: BotScriptItemController;
  botEditorController: BotEditorController;

  unreadsController: UnreadsController;
  dialogsTableController: CommonTableController;
  dialogsMessagesTableController: CommonTableController;

  constructor() {
    makeAutoObservable(this);
    this.clientTableController = new CommonTableController(EEntityType.CLIENT);
    this.clientItemController = new ClientItemController(this);

    this.searchCoreTableController = new CommonTableController(
      EEntityType.SEARCH_CORE,
    );
    this.searchCoreItemController = new SearchCoreItemController(this);

    this.leadSourceTableController = new LeadSourceTableController(this);
    this.leadSourceItemController = new LeadSourceItemController(this);

    this.locationTableController = new CommonTableController(
      EEntityType.LOCATION,
      {
        limit: 10000,
      },
    );
    this.locationItemController = new LocationItemController(this);

    this.categoryTableController = new CommonTableController(
      EEntityType.CATEGORY,
    );
    this.categoryItemController = new CategoryItemController(this);

    this.targetListTableController = new CommonTableController(
      EEntityType.TARGET_LIST,
    );
    this.targetListItemController = new TargetListItemController(this);

    this.settingsLinkTableController = new CommonTableController(
      EEntityType.SETTINGS_LINK,
      {
        limit: 99999,
      },
    );

    this.schedullingController = new SchedullingController(this);
    this.schedullingController.loadCounters();

    this.settingsLinkItemController = new SettingsLinkItemController(this);

    this.feedChatController = new FeedChatController(this);

    this.nodeItemController = new NodeItemController(this);
    this.nodeUploadController = new NodeUploadController(this);
    this.nodeTableController = new NodeTableController(this);

    this.messageLogController = new MessageLogController(this);

    this.semanticsController = new SemanticController(this);

    this.transactionItemController = new TransactionItemController(this);
    this.transactionTableController = new CommonTableController(
      EEntityType.TRANSACTION,
    );
    this.profitReportController = new ProfitReportController(this);
    this.nodeReportController = new NodeReportController(this);

    this.botElementTableController = new CommonTableController(
      EEntityType.BOT_ELEMENT,
      {
        limit: 99999,
      },
    );
    this.botElementItemController = new BotElementItemController(this);

    this.botScriptTableController = new CommonTableController(
      EEntityType.BOT_SCRIPT,
    );
    this.botScriptItemController = new BotScriptItemController(this);

    this.botEditorController = new BotEditorController(this);

    this.unreadsController = new UnreadsController(this);

    this.dialogsTableController = new CommonTableController(
      EEntityType.MESSAGING_PRIVATE_CHAT,
    );
    this.dialogsMessagesTableController = new CommonTableController(
      EEntityType.MESSAGING_PRIVATE_CHAT_MESSAGE,
    );

    this.init().then();
  }

  init = async () => {
    this.state = EAsyncState.LOADING;
    this.me = await apiController.getMe();
    this.unreadsController.init().then();
    this.state = EAsyncState.IDLE;
  };

  isRoot = () => {
    return this.me?.is_root || false;
  };
}

export const root = new RootController();
