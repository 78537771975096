import { first, flatten, last } from "lodash";
import { replaceAll } from "./text";
import { EValidationReason } from "../_enums/EValidationReason";
import { EConditionTerm } from "../_interfaces/nodeTreeEditor/INodeTreeEditor";
import { deobfuscateSpam } from "./messageGeneration/obfuscator";
import { IOccurancesMap } from "./searchCoreEngine";
import {
  ISearchCoreConditionContent,
  ProcessTerm,
} from "../_interfaces/searchCoreEngine";
import {
  ALL_PATTERNS,
  ECustomPattern,
  ICustomPatternsOccurances,
} from "./customPatternProcessor";

export const queriesFromText = (text) => {
  const queries = {};
  for (const grpUsername of text.trim().split("\n")) {
    const trimmed = grpUsername.trim();
    let final = trimmed;
    if (trimmed.includes("/")) {
      final = last(trimmed.split("/"));
    }
    if (trimmed.includes("@")) {
      final = last(trimmed.split("@"));
    }
    if (!!final && final.length > 0) {
      queries[final] = 1;
    }
  }
  return Object.keys(queries);
};

export const linkNumber = (msg) => {
  //const linksMatch = msg.match(/t.me/gi);
  const httpMatch = msg.match(/http/gi);
  return /*(linksMatch ? linksMatch.length : 0) +*/ httpMatch
    ? httpMatch.length
    : 0;
};

export const nextSpaceIndex = (msg: string, fromIndex) => {
  let index = 0;
  for (let c of msg) {
    if (c === " " && index > fromIndex) return index;
    index++;
  }
  return -1;
};

export type IMatchSelection = {
  startIndex: number;
  endIndex?: number;
  ignore?: boolean;
};
export const NO_SELECTION: IMatchSelection = { startIndex: -1 };

export const findAllStringOccurrences = (str, keyword) => {
  const occurrences: any = [];
  const regex = new RegExp(keyword, "gm");
  let match;
  const nextOccur = () => {
    const occ = regex.exec(str);
    if (occ && occ[0] === "") {
      return null;
    }
    return occ;
  };
  while ((match = nextOccur()) !== null) {
    let found = match[0];
    const startIndex = match.index || 0;
    const endIndex = startIndex + found.length;
    occurrences.push({ startIndex, endIndex });
  }
  return occurrences;
};

export const isValidFilter = (
  msg,
  filter,
  acOccurances: IOccurancesMap,
  patterns: ICustomPatternsOccurances,
): IMatchSelection[] => {
  if (typeof filter === "string") {
    if (filter.startsWith("rx:/")) {
      return [NO_SELECTION];
    }
    if (ALL_PATTERNS.has(filter as ECustomPattern)) {
      return patterns[filter];
    }

    // Common string pattern
    if (filter === "") {
      return [NO_SELECTION];
    }
    const exactMatches = acOccurances[filter];
    if (!exactMatches) return [NO_SELECTION];

    let ptrLen = filter.length;
    return exactMatches.map((endIndex) => {
      return { startIndex: endIndex - (ptrLen - 1), endIndex: endIndex };
    });
  }
  return processTerm(msg, filter, acOccurances, patterns);
};

export const andTerm = (matches: IMatchSelection[][]): IMatchSelection[] => {
  const values = flatten(matches);
  if (!values.length) return [NO_SELECTION];

  let start = Infinity;
  let end = -Infinity;
  for (let v of values) {
    if (v.ignore) continue;
    if (v.startIndex === -1) return [NO_SELECTION];
    if (v.startIndex < start) start = v.startIndex;
    //@ts-ignore
    if (v.endIndex > end) end = v.endIndex;
  }

  if (start === Infinity) return [NO_SELECTION];

  return [{ startIndex: start, endIndex: end }];
};

export const orTerm = (matches: IMatchSelection[][]): IMatchSelection[] => {
  const values = flatten(matches);
  if (values.length === 1 && values[0].startIndex !== -1) return [values[0]];
  const filteredVals = values.filter((v) => v.startIndex !== -1);
  if (!!filteredVals.length) {
    return filteredVals;
  }
  return [NO_SELECTION];
};

/* Intersects all viable variants
 * Input: [ [A,B], [C, D], [E] ]
 * Output:
 * [A, C, E]
 * [B, C, E]
 * [A, D, E]
 * [B, D, E]
 */
const generateSeqCombinations = (arrays) => {
  const result: any = [];
  const backtrack = (current, index) => {
    if (index === arrays.length) {
      result.push([...current]);
      return;
    }
    for (let i = 0; i < arrays[index].length; i++) {
      current.push(arrays[index][i]);
      backtrack(current, index + 1);
      current.pop();
    }
  };

  backtrack([], 0);
  return result;
};

const seqComboResolve = (
  values: IMatchSelection[],
  { max_distance }: { max_distance: number } = { max_distance: 75 },
): IMatchSelection => {
  let prevItem = NO_SELECTION;
  let finalStartIndex = -1;
  for (let v of values) {
    if (v.ignore) continue;
    if (v.startIndex === -1) return NO_SELECTION;
    if (finalStartIndex === -1) {
      finalStartIndex = v.startIndex;
    }
    if (v.startIndex > prevItem.startIndex) {
      if (prevItem.startIndex !== -1) {
        // @ts-ignore
        const diff = v.startIndex - (prevItem.endIndex + 1);
        if (diff > max_distance) {
          return NO_SELECTION;
        }
      }
      prevItem = v;
    } else {
      return NO_SELECTION;
    }
  }
  return { startIndex: finalStartIndex, endIndex: prevItem.endIndex };
};

export const seqTerm = (
  matches: IMatchSelection[][],
  { max_distance }: { max_distance: number } = { max_distance: 75 },
): IMatchSelection[] => {
  // Validate matches before expensive recursive search
  for (let values of matches) {
    const firstValue = first(values);
    if (!firstValue || firstValue.startIndex === -1) {
      return [NO_SELECTION];
    }
  }

  const combinations = generateSeqCombinations(matches);
  const output: any = [];
  for (let comb of combinations) {
    const comboResult = seqComboResolve(comb, { max_distance });
    if (comboResult.startIndex !== -1) {
      output.push(comboResult);
    }
  }

  if (!output.length) {
    return [NO_SELECTION];
  }
  return output;
};

/*  
nor = inverse or
*/
/* export const norTerm = (matches: IMatchSelection[][]): IMatchSelection[] => {
  const fullValidOrValues = orTerm(matches);
  for (let results of matches) {
    let startIndex = -1;
    const firstOr = first(fullValidOrValues);
    startIndex = firstOr ? firstOr.startIndex : startIndex;

    const [firstValue, lastValue] = [first(results), last(results)];

    // Inverse
    if (startIndex !== -1) {
      return [NO_SELECTION];
    }
    return [
      {
        startIndex: firstValue.startIndex === -1 ? 0 : firstValue.startIndex,
        endIndex: firstValue.startIndex === -1 ? 0 : lastValue.endIndex,
      },
    ];
  }
  return [NO_SELECTION];
}; */
export const norTerm = (matches: IMatchSelection[][]): IMatchSelection[] => {
  const fullValidOrValues = orTerm(matches);
  const firstOr = first(fullValidOrValues);
  let startIndex = -1;
  startIndex = firstOr ? firstOr.startIndex : startIndex;
  if (startIndex !== -1) {
    return [NO_SELECTION];
  }
  for (let results of matches) {
    const [firstValue, lastValue] = [first(results), last(results)];

    // Inverse
    if (startIndex !== -1) {
      return [NO_SELECTION];
    }
    return [
      {
        startIndex: 0,
        endIndex: 0,
        ignore: true,
      },
    ];
  }
  return [NO_SELECTION];
};

export const termFn = {
  [EConditionTerm.AND]: andTerm,
  [EConditionTerm.OR]: orTerm,
  [EConditionTerm.SEQ]: seqTerm,
  [EConditionTerm.NOR]: norTerm,
};

/* exit if first filter is passes logic OR */
export const processFastOr = (
  msg: string,
  condition,
  acOccurances: IOccurancesMap,
  patterns: ICustomPatternsOccurances,
): IMatchSelection[] => {
  if (!condition) return [NO_SELECTION];
  const { term, content } = condition;

  if (term !== EConditionTerm.OR) {
    return processTerm(msg, condition, acOccurances, patterns);
  }

  let res = [NO_SELECTION];
  for (let filter of content) {
    res = isValidFilter(msg, filter, acOccurances, patterns);
    if (res.length === 0) {
      continue;
    }
    let firstRes = first(res);
    if (firstRes && firstRes?.startIndex !== -1) {
      break;
    }
  }

  return res;
};

export const processTerm = (
  msg: string,
  condition: ProcessTerm,
  acOccurances: IOccurancesMap,
  patterns: ICustomPatternsOccurances,
): IMatchSelection[] => {
  if (!condition) return [NO_SELECTION];
  const { term, content, options } = condition;
  let values: IMatchSelection[][] = [];

  values = content.map((filter) =>
    isValidFilter(msg, filter, acOccurances, patterns),
  );

  return termFn[term](values, options);
};

export const prepareTextForProcessing = (
  messageText,
  needDeobfuscate = true,
): string => {
  let msg = messageText.toLowerCase().trim();
  if (needDeobfuscate) {
    msg = deobfuscateSpam(msg);
  }
  //msg = replaceAll(msg, /[^\p{L}\d0-9!\$€₽?,%#+]/gu, " "); // Remove all trash symbols like emoji and other bs.
  //@ts-ignore
  msg = msg.replace(/[^\p{L}\d0-9!\$€₽?,@%#+]/gu, " ");
  msg = replaceAll(msg, /(\!|\?|\,|\%|\#|\$|\€|\₽)/g, " $1 "); // Wrap neccessary symbols with a spaces
  msg = " " + msg + " "; // Wrap full message with spaces
  msg = replaceAll(msg, /\s+/g, " "); // Remove repeating spaces with only one space
  return msg;
};

export const isTextValidPos = (
  msg: string,
  conditionsContent: ISearchCoreConditionContent,
  acOccurances: IOccurancesMap,
  patterns: ICustomPatternsOccurances,
  isFast = true,
): { reason: EValidationReason; positions: IMatchSelection[] } => {
  if (!msg) return { reason: EValidationReason.NO_LIST, positions: [] };

  const { whitelist, blacklist, max_length, max_links } = conditionsContent;

  if (msg.length > max_length)
    return { reason: EValidationReason.MAX_LENGTH, positions: [] };

  if (linkNumber(msg) > max_links)
    return { reason: EValidationReason.MAX_LINKS, positions: [] };

  const blResult = isFast
    ? processFastOr(msg, blacklist, acOccurances, patterns)
    : processTerm(msg, blacklist, acOccurances, patterns);
  const firstBlRes = first(blResult);

  //@ts-ignore
  if (firstBlRes.startIndex !== -1) {
    return { reason: EValidationReason.BLACK_LIST, positions: blResult };
  }

  let wlResult = isFast
    ? processFastOr(msg, whitelist, acOccurances, patterns)
    : processTerm(msg, whitelist, acOccurances, patterns);
  const firstWlRes = first(wlResult);
  //wlResult = wlResult.startIndex > 0 ? wlResult - 1 : wlResult;

  // If not NO_SELECTION
  //@ts-ignore
  if (firstWlRes.startIndex !== -1) {
    return { reason: EValidationReason.WHITE_LIST, positions: wlResult };
  }

  return { reason: EValidationReason.NO_LIST, positions: [] };
};

export const isTextValid = (
  messageText,
  conditionsContent: ISearchCoreConditionContent,
  acOccurances: IOccurancesMap,
  patterns: ICustomPatternsOccurances,
): boolean => {
  return (
    isTextValidPos(messageText, conditionsContent, acOccurances, patterns, true)
      .reason === EValidationReason.WHITE_LIST
  );
};

export default 1;
