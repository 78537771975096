import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { root } from "../../../controllers/RootController";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { Loader } from "../../../components/Loader/Loader";
import { observer } from "mobx-react-lite";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { BotElementItemForm } from "./BotElementItemForm";
import { getBotElementTitle } from "../../../../_shared/Util/botElementUtils";

interface IBotElementItemProps {}

export const BotElementItem: React.FunctionComponent<IBotElementItemProps> =
  observer((props) => {
    let params = useParams();
    let navigate = useNavigate();
    const { botElementId } = params;
    const controller = root.botElementItemController;
    useEffect(() => {
      controller.initItem(botElementId).then();
    }, []);
    if (controller.entityController.state !== EAsyncState.IDLE)
      return <Loader />;
    const item = controller.entityController.item;

    return (
      <PageWrapper
        header={botElementId ? getBotElementTitle(item) : `Новая Фаза`}
        onSave={async () => {
          const newItem = await controller.entityController.saveItem();
          navigate(`/botScripts/elements/${newItem._id}`);
        }}
        onDelete={
          botElementId
            ? async () => {
                const result = await controller.entityController.deleteItem();
                if (result) {
                  navigate(`/botScripts/elements`);
                }
              }
            : undefined
        }
        state={EAsyncState.IDLE}
      >
        <BotElementItemForm />
      </PageWrapper>
    );
  });
