import React, { useEffect } from "react";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { root } from "../../../controllers/RootController";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  ControlledTable,
  ITableOptions,
} from "../../../components/Table/Table";
import { find } from "lodash";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";

const tableOptions: ITableOptions = {
  filters: {
    name: {
      title: "Поиск",
      makeQuery: (val) => {
        const regex = { $regex: val, $options: "i" };
        return {
          $and: [
            {
              $or: [{ title: regex }],
            },
          ],
        };
      },
      type: "string",
    },
  },
  columns: [
    {
      title: "ID",
      field: "_id",
      format: (val: string) => {
        return "..." + val.slice(val.length - 6);
      },
    },
    {
      title: "Приват",
      field: "is_private",
      format: (val: string) => {
        if (!val) return "";
        return "Да";
      },
    },
    {
      title: "Категория",
      field: "category_id",
      format: (val: string, item: any) => {
        const category = find(root.categoryTableController.items, { _id: val });
        return !category ? "" : category.title;
      },
    },
    { title: "Название", field: "title" },
  ],
};

export const SearchCoreTable = observer(() => {
  let navigate = useNavigate();
  useEffect(() => {
    root.categoryTableController
      .loadTable()
      .then(root.searchCoreTableController.loadTable);
  }, []);
  return (
    <PageWrapper
      header={"Поисковые ядра"}
      onNew={() => navigate("/searchCores/new")}
      state={EAsyncState.IDLE}
    >
      <ControlledTable
        controller={root.searchCoreTableController}
        options={tableOptions}
        getItemHref={(item) => `/searchCores/${item._id}`}
      />
    </PageWrapper>
  );
});
