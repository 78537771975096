import style from "../BotElementItem.module.scss";
import { observer } from "mobx-react-lite";
import { FormInput } from "../../../../components/FormInput/FormInput";
import { GridItem } from "../../../../components/Grid/GridItem";
import { Form } from "../../../../components/Form/Form";
import { BotElementItemController } from "../../../../controllers/BotScript/BotElementItemController";
import { GridRow } from "../../../../components/Grid/GridRow";

export const BotElementTimeoutForm: React.FunctionComponent<{
  controller: BotElementItemController;
}> = observer(({ controller }) => {
  const item = controller.entityController.item;
  return (
    <Form>
      <GridRow>
        <GridItem>
          <FormInput
            path={"element_data.delay"}
            item={item}
            title={"Задержка (мин)"}
            onChange={controller.entityController.changeItem(
              "element_data.delay",
            )}
          />
        </GridItem>
      </GridRow>
    </Form>
  );
});
