import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { root } from "../../../controllers/RootController";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { Loader } from "../../../components/Loader/Loader";
import { FormInput } from "../../../components/FormInput/FormInput";
import { observer } from "mobx-react-lite";
import { Form } from "../../../components/Form/Form";
import { GridItem } from "../../../components/Grid/GridItem";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { GridRow } from "../../../components/Grid/GridRow";
import { Button } from "../../../components/Button/Button";
import { AddTargetDumpModal } from "./AddTargetDumpModal";
import { InternalClientTable } from "../../../components/InternalClientTable/InternalClientTable";

interface ICategoryItemProps {}

export const TargetListItem: React.FunctionComponent<ICategoryItemProps> =
  observer((props) => {
    let params = useParams();
    let navigate = useNavigate();
    const { targetListId } = params;
    const entityController = root.targetListItemController.entityController;
    const tableController =
      root.targetListItemController.targetClientTableController;
    useEffect(() => {
      root.targetListItemController.initItem(targetListId).then();
    }, []);
    if (entityController.state !== EAsyncState.IDLE) return <Loader />;
    const item = entityController.item;
    if (!item) return null;
    return (
      <PageWrapper
        header={targetListId ? item.title : `Новый таргетинг`}
        onSave={async () => {
          const newItem = await entityController.saveItem();
          navigate(`/targetLists/${newItem._id}`);
        }}
        onDelete={
          targetListId
            ? async () => {
                const result = await entityController.deleteItem();
                if (result) {
                  navigate(`/targetLists`);
                }
              }
            : undefined
        }
        state={EAsyncState.IDLE}
      >
        <Form>
          <GridRow>
            <GridItem>
              <FormInput
                path={"title"}
                item={item}
                title={"Название"}
                onChange={entityController.changeItem("title")}
              />
            </GridItem>
          </GridRow>
          <GridRow>
            <h3>Напарсенные лиды</h3>
          </GridRow>
          <GridRow>
            {!!targetListId && (
              <Button
                text={"Добавить выгрузку из чата"}
                onClick={root.targetListItemController.openAddingDumps}
              />
            )}
          </GridRow>
          <GridRow>
            <InternalClientTable
              tableController={tableController}
              onItemClick={(item) =>
                root.clientItemController.openCard(item._id)
              }
            />
          </GridRow>
        </Form>
        <AddTargetDumpModal controller={root.targetListItemController} />
      </PageWrapper>
    );
  });
