import { Modal } from "../../../components/Modal/Modal";
import React from "react";
import { TextArea } from "../../../components/Input/Input";
import { Button, EButtonType } from "../../../components/Button/Button";
import { GridRow } from "../../../components/Grid/GridRow";
import { Form } from "../../../components/Form/Form";
import { observer } from "mobx-react-lite";
import { LeadSourceTableController } from "../../../controllers/LeadSource/LeadSourceTableController";
import { EGridItemSize, GridItem } from "../../../components/Grid/GridItem";
import { ScanStatus } from "./ScanStatus";
import { Toggle } from "../../../components/Toggle/Toggle";
import style from "./LeadSourceTable.module.scss";
import { ITableOptions, Table } from "../../../components/Table/Table";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { Loader } from "@chatscope/chat-ui-kit-react";
import { root } from "../../../controllers/RootController";

const AddMode: React.FunctionComponent<{
  controller: LeadSourceTableController;
}> = observer(({ controller }) => {
  const { sourcesText, changeSourcesText } = controller;
  return (
    <div>
      <h3>Чаты для добавления</h3>
      <TextArea
        placeholder={
          "Введите ссылки на чаты, начиная каждый новый чат с новой строки"
        }
        onChange={(e) => changeSourcesText(e.target.value)}
        value={sourcesText}
      />
      <Button
        text={"Добавить"}
        styling={EButtonType.ACCEPT}
        disabled={sourcesText.trim() === ""}
        onClick={() => controller.processSources()}
      />
    </div>
  );
});

const processedTableOptions: ITableOptions = {
  filters: {},
  columns: [
    {
      title: "Query",
      field: "query",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Node Phone",
      field: "nodePhone",
    },
    {
      title: "Added chats",
      field: "addedChats",
      format: (val: string) => {
        if (!val) return 0;
        const parsed = JSON.parse(val);
        return parsed.length;
      },
    },
    {
      title: "Updated chats",
      field: "updatedChats",
      format: (val: string) => {
        if (!val) return 0;
        const parsed = JSON.parse(val);
        return parsed.length;
      },
    },
    {
      title: "Перепланировать",
      field: "error",
      format: (val: string, item) => {
        return (
          <Button
            text="Перепланировать"
            onClick={() =>
              root.leadSourceTableController.processSources(item.query)
            }
          />
        );
      },
    },
  ],
};

const queueTableOptions: ITableOptions = {
  filters: {},
  columns: [
    {
      title: "Query",
      field: "query",
    },
    {
      title: "Убрать",
      field: "error",
      format: (val: string, item) => {
        return (
          <Button
            text="Убрать"
            onClick={() =>
              root.leadSourceTableController.dequeueQuery(item.query)
            }
          />
        );
      },
    },
  ],
};

const ResultsMode: React.FunctionComponent<{
  controller: LeadSourceTableController;
}> = observer(({ controller }) => {
  const { scanLoadingState, sourceScanModalMode, scanState } = controller;

  if (scanLoadingState === EAsyncState.LOADING) {
    return <Loader />;
  }

  if (scanLoadingState === EAsyncState.FAILURE) {
    return <span>Something went wrong</span>;
  }

  const items =
    sourceScanModalMode === "processed" ? scanState.processed : scanState.queue;
  if (!items) {
    return null;
  }

  let percentage = 0;
  if (scanState.processed && !scanState.queue) {
    percentage = 100;
  }
  if (scanState.processed && scanState.queue) {
    percentage =
      (scanState.processed.length /
        (scanState.processed.length + scanState.queue.length || 1)) *
      100;
  }

  return (
    <div>
      Количество: {items.length}. Процент: {percentage.toFixed(2)}
      <Table
        items={items}
        options={
          sourceScanModalMode === "processed"
            ? processedTableOptions
            : queueTableOptions
        }
      />
    </div>
  );
});

const togglerVariants = [
  { label: "Добавление в очередь", value: "add" },
  { label: "На очереди", value: "queue" },
  { label: "Обработано", value: "processed" },
];

export const AddNewSourcesModal: React.FunctionComponent<{
  controller: LeadSourceTableController;
}> = observer(({ controller }) => {
  const {
    closeAddNewSourceModal,
    sourceScanModalOpen,
    sourceScanModalMode,
    changeSourceScanMode,
    resetScan,
  } = controller;
  if (!sourceScanModalOpen) return null;

  return (
    <Modal
      header={"Добавление новых источников"}
      onClose={closeAddNewSourceModal}
      innerStyle={{ minWidth: 800 }}
    >
      <div className={style.togglerWrapper}>
        <Toggle
          variants={togglerVariants}
          selected={sourceScanModalMode}
          onSelect={changeSourceScanMode}
        />
        <Button
          text="Сброс сканирования"
          onClick={resetScan}
          styling={EButtonType.REJECT}
        />
      </div>
      <Form>
        <GridRow>
          <GridItem size={EGridItemSize.full}>
            {sourceScanModalMode === "add" ? (
              <AddMode controller={controller} />
            ) : (
              <ResultsMode controller={controller} />
            )}
          </GridItem>
        </GridRow>
      </Form>
    </Modal>
  );
});
