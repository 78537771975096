import style from "../../searchCore/SearchCoreItem/SearchCoreItem.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { root } from "../../../controllers/RootController";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { Loader } from "../../../components/Loader/Loader";
import { FormInput } from "../../../components/FormInput/FormInput";
import { observer } from "mobx-react-lite";
import { Form } from "../../../components/Form/Form";
import { GridItem } from "../../../components/Grid/GridItem";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { GridRow } from "../../../components/Grid/GridRow";
import { find } from "lodash";
import { NodeTreeEditor } from "../../../components/NodeTreeEditor/NodeTreeEditor";
import { NodeEditModal } from "../../../components/NodeTreeEditor/NodeEditModal";
import { TestingModal } from "../../searchCore/SearchCoreItem/TestingModal";
import { Button, EButtonType } from "../../../components/Button/Button";
import { AsyncInput } from "../../../components/AsyncInput/AsyncInput";
import { EEntityType } from "../../../../_shared/_enums/EEntityType";

interface ILocationItemProps {}

export const LocationItem: React.FunctionComponent<ILocationItemProps> =
  observer((props) => {
    let params = useParams();
    let navigate = useNavigate();
    const { locationId } = params;
    const controller = root.locationItemController;
    const entityController = root.locationItemController.entityController;
    useEffect(() => {
      entityController.initItem(locationId).then();
    }, []);
    if (entityController.state !== EAsyncState.IDLE) return <Loader />;
    const item = entityController.item;
    const selectedCountry = find(controller.location_ids, {
      _id: item.parent_id,
    });
    return (
      <PageWrapper
        header={
          locationId
            ? `${selectedCountry ? selectedCountry.title + " :" : ""} ${item.title}`
            : `Новая локация`
        }
        onSave={async () => {
          const newItem = await entityController.saveItem();
          navigate(`/locations/${newItem._id}`);
        }}
        actionBar={
          <Button
            text={"👀 Проверка"}
            onClick={() => controller.conditionalController.openTesting()}
            styling={EButtonType.DEFAULT}
          />
        }
        onDelete={
          locationId
            ? async () => {
                const result = await entityController.deleteItem();
                if (result) {
                  navigate(`/locations`);
                }
              }
            : undefined
        }
        state={EAsyncState.IDLE}
      >
        <Form>
          <GridRow>
            <GridItem>
              <FormInput
                path={"title"}
                item={item}
                title={"Название"}
                onChange={entityController.changeItem("title")}
              />
            </GridItem>
            <GridItem>
              <FormInput
                path={"is_private"}
                item={item}
                type={"checkbox"}
                title={"Приватный"}
                onChange={entityController.changeItem("is_private")}
              />
            </GridItem>
            <GridItem>
              <label>Принадлежит к</label>
              <AsyncInput
                value={item.parent_id || null}
                entityType={EEntityType.LOCATION}
                onChange={(newVal) =>
                  entityController.changeItem("parent_id")(newVal)
                }
              />
            </GridItem>
          </GridRow>
          <GridRow>
            <div className={style.ConditionEditowWrapper}>
              <h3>Whitelist</h3>
              <NodeTreeEditor
                value={controller.conditionalController.whiteListController.getContent()}
                controller={
                  controller.conditionalController.whiteListController
                }
              />
            </div>
            <div className={style.ConditionEditowWrapper}>
              <h3>Blacklist</h3>
              <NodeTreeEditor
                value={controller.conditionalController.blackListController.getContent()}
                controller={
                  controller.conditionalController.blackListController
                }
              />
            </div>
          </GridRow>
        </Form>
        <TestingModal controller={controller.conditionalController} />
        <NodeEditModal
          controller={controller.conditionalController.whiteListController}
        />
        <NodeEditModal
          controller={controller.conditionalController.blackListController}
        />
      </PageWrapper>
    );
  });
