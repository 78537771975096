import { ILocation } from "../../../_shared/Database/location";
import { CommonEntityController } from "../_common/CommonEntityController";
import { makeAutoObservable } from "mobx";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { RootController } from "../RootController";
import { ConditionalController } from "../_common/ConditionalController";
import { apiController } from "../ApiController";
import { newLocationConditions } from "../../../_shared/Constants/ItemConstants";
import { each } from "lodash";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";

const newItem: ILocation = {
  title: "",
  parent_id: "",
  is_private: false,
  conditions: newLocationConditions,
};

export class LocationItemController {
  root: RootController;
  conditionalController: ConditionalController;
  entityController: CommonEntityController<ILocation>;
  location_ids: any = [];
  location_map: any = {};

  constructor(root: RootController) {
    this.root = root;
    this.entityController = new CommonEntityController<ILocation>(
      EEntityType.LOCATION,
      newItem,
    );
    this.conditionalController = new ConditionalController(
      this.entityController,
    );
    apiController[EEntityType.LOCATION]
      [ECrudMethods.LIST]({}, 0, 10000)
      .then((resp) => {
        const locations = resp.items;
        this.location_ids = locations;
        each(locations, (loc) => {
          this.location_map[loc._id] = loc;
        });
      });
    makeAutoObservable(this);
  }
}
