import style from "./SearchCoreItem.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { root } from "../../../controllers/RootController";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { Loader } from "../../../components/Loader/Loader";
import {
  FormAsyncInput,
  FormInput,
} from "../../../components/FormInput/FormInput";
import { observer } from "mobx-react-lite";
import { Form } from "../../../components/Form/Form";
import { EGridItemSize, GridItem } from "../../../components/Grid/GridItem";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { GridRow } from "../../../components/Grid/GridRow";
import { NodeTreeEditor } from "../../../components/NodeTreeEditor/NodeTreeEditor";
import { NodeEditModal } from "../../../components/NodeTreeEditor/NodeEditModal";
import { Button, EButtonType } from "../../../components/Button/Button";
import { TestingModal } from "./TestingModal";
import { AsyncInput } from "../../../components/AsyncInput/AsyncInput";
import { EEntityType } from "../../../../_shared/_enums/EEntityType";

interface ISearchCoreItemProps {}

export const SearchCoreItem: React.FunctionComponent<ISearchCoreItemProps> =
  observer((props) => {
    let params = useParams();
    let navigate = useNavigate();
    const { searchCoreId } = params;
    const controller = root.searchCoreItemController;
    useEffect(() => {
      controller.entityController.initItem(searchCoreId).then();
    }, []);
    if (controller.entityController.state !== EAsyncState.IDLE)
      return <Loader />;
    const item = controller.entityController.item;
    return (
      <PageWrapper
        header={
          searchCoreId ? `Поисковое ядро ${item.title}` : `Новое поисковое ядро`
        }
        actionBar={
          <Button
            text={"👀 Проверка"}
            onClick={() => controller.conditionalController.openTesting()}
            styling={EButtonType.DEFAULT}
          />
        }
        onSave={async () => {
          const newItem = await controller.entityController.saveItem();
          navigate(`/searchCores/${newItem._id}`);
        }}
        onDelete={
          searchCoreId
            ? async () => {
                const result = await controller.entityController.deleteItem();
                if (result) {
                  navigate(`/searchCores`);
                }
              }
            : undefined
        }
        state={EAsyncState.IDLE}
      >
        <Form>
          <GridRow>
            <GridItem>
              <FormInput
                path={"title"}
                item={item}
                title={"Название"}
                onChange={controller.entityController.changeItem("title")}
              />
            </GridItem>
            <GridItem>
              <label>Категория</label>
              <AsyncInput
                entityType={EEntityType.CATEGORY}
                value={item.category_id}
                onChange={(newVal) =>
                  controller.entityController.changeItem("category_id")(newVal)
                }
              />
            </GridItem>
            <GridItem>
              <FormInput
                path={"hashtag"}
                item={item}
                title={"Хештег"}
                onChange={controller.entityController.changeItem("hashtag")}
              />
            </GridItem>
            <GridItem>
              <FormInput
                path={"is_private"}
                item={item}
                type={"checkbox"}
                title={"Приватный"}
                onChange={controller.entityController.changeItem("is_private")}
              />
            </GridItem>
            <GridItem>
              <FormInput
                path={"is_temporary"}
                item={item}
                type={"checkbox"}
                title={"Временная"}
                onChange={controller.entityController.changeItem(
                  "is_temporary",
                )}
              />
            </GridItem>
          </GridRow>
          <GridRow>
            <GridItem>
              <FormInput
                type={"number"}
                path={"conditions.content.max_links"}
                item={item}
                title={"Макс. кол-во ссылок"}
                onChange={controller.entityController.changeItem(
                  "conditions.content.max_links",
                )}
              />
            </GridItem>
            <GridItem>
              <FormInput
                type={"number"}
                path={"conditions.content.max_length"}
                item={item}
                title={"Макс. кол-во символов"}
                onChange={controller.entityController.changeItem(
                  "conditions.content.max_length",
                )}
              />
            </GridItem>
            <GridItem>
              <FormInput
                path={"can_include_photos"}
                item={item}
                type={"checkbox"}
                title={"Может содержать картинки?"}
                onChange={controller.entityController.changeItem(
                  "can_include_photos",
                )}
              />
            </GridItem>
          </GridRow>
          <GridRow>
            <GridItem size={EGridItemSize.half}>
              <FormInput
                path={"ai_prompt"}
                item={item}
                title={"Промпт для AI"}
                onChange={controller.entityController.changeItem("ai_prompt")}
              />
            </GridItem>
            {(item.ai_prompt || "").trim() !== "" ? (
              <GridItem size={EGridItemSize.medium}>
                <FormAsyncInput
                  entityType={EEntityType.SEARCH_CORE}
                  path={"fallback_core_id"}
                  item={item}
                  title={"Фоллбек ядро"}
                  onChange={controller.entityController.changeItem(
                    "fallback_core_id",
                  )}
                />
              </GridItem>
            ) : null}
          </GridRow>
          <GridRow>
            <div className={style.ConditionEditowWrapper}>
              <h3>Whitelist</h3>
              <NodeTreeEditor
                value={controller.conditionalController.whiteListController.getContent()}
                controller={
                  controller.conditionalController.whiteListController
                }
              />
            </div>
            <div className={style.ConditionEditowWrapper}>
              <h3>Blacklist</h3>
              <NodeTreeEditor
                value={controller.conditionalController.blackListController.getContent()}
                controller={
                  controller.conditionalController.blackListController
                }
              />
            </div>
          </GridRow>
        </Form>
        <TestingModal controller={controller.conditionalController} />
        <NodeEditModal
          controller={controller.conditionalController.whiteListController}
        />
        <NodeEditModal
          controller={controller.conditionalController.blackListController}
        />
      </PageWrapper>
    );
  });
