import style from "../BotElementItem.module.scss";
import { observer } from "mobx-react-lite";
import { FormInput } from "../../../../components/FormInput/FormInput";
import { EGridItemSize, GridItem } from "../../../../components/Grid/GridItem";
import { Form } from "../../../../components/Form/Form";
import { EConditionTerm } from "../../../../../_shared/_interfaces/nodeTreeEditor/INodeTreeEditor";
import { NodeEditModal } from "../../../../components/NodeTreeEditor/NodeEditModal";
import { BotElementItemController } from "../../../../controllers/BotScript/BotElementItemController";
import { GridRow } from "../../../../components/Grid/GridRow";
import { NodeTreeEditor } from "../../../../components/NodeTreeEditor/NodeTreeEditor";
import { ElementTestingModal } from "../ElementTestingModal";
import { Input } from "../../../../components/Input/Input";
import { get } from "lodash";
import { ClientStatus } from "../../../../components/ClientStatus/ClientStatus";
import { CLIENT_STATUSES } from "../../../../../_shared/Constants/ClientStatuses";
import {
  getDateString,
  getRelativeDuration,
} from "../../../../../_shared/Util/dates";
import {
  BOT_COND_EXISTS,
  BOT_DATE_TYPES,
  BOT_DATE_TYPES_KEYED,
  BOT_ELEMENT_CONDITIONS,
  BOT_ELEMENT_CONDITIONS_KEYED,
  BOT_ELEMENT_VALUE_KEYS,
  EBotCondition,
  EBotElementDateEditorType,
  EBotElementValueKey,
} from "../../../../../_shared/_enums/EBotElementEnums";
import { AsyncInput } from "../../../../components/AsyncInput/AsyncInput";
import { EEntityType } from "../../../../../_shared/_enums/EEntityType";
import { root } from "../../../../controllers/RootController";
import React from "react";
import { ComboboxInput } from "../../../../components/ComboboxInput/ComboboxInput";

const NumericValueEditor: React.FunctionComponent<{
  onValueChange: (newVal) => void;
  value: any;
}> = ({ onValueChange, value }) => {
  const { k, cond, val } = value ? value : { k: null, cond: null, val: null };
  return (
    <div className={style.editNodeInput}>
      <ComboboxInput
        options={BOT_ELEMENT_CONDITIONS}
        value={cond}
        onChange={(v) => onValueChange({ k, cond: v, val })}
      />
      <Input
        value={val}
        autoFocus={true}
        isEnabled={true}
        onValueChange={(v) => onValueChange({ k, cond, val: v })}
      />
    </div>
  );
};

const DateValueEditor: React.FunctionComponent<{
  onValueChange: (newVal) => void;
  value: any;
}> = ({ onValueChange, value }) => {
  const cond = get(value, "cond", EBotCondition.GTE);

  const { date_type, modifier } = {
    date_type: get(
      value,
      "val.date_type",
      EBotElementDateEditorType.CURRENT_DATE,
    ),
    modifier: get(value, "val.modifier", 0),
  };

  const handleChange = ({ cond, date_type, modifier }) => {
    onValueChange({
      k: value.k,
      cond: cond || EBotCondition.GTE,
      val: {
        date_type: date_type || EBotElementDateEditorType.CURRENT_DATE,
        modifier: modifier || 0,
      },
    });
  };

  return (
    <div className={style.editNodeInput}>
      <ComboboxInput
        options={BOT_ELEMENT_CONDITIONS}
        value={cond}
        onChange={(v) => handleChange({ cond: v, date_type, modifier })}
      />
      <ComboboxInput
        options={BOT_DATE_TYPES}
        value={date_type}
        onChange={(v) => handleChange({ cond, date_type: v, modifier })}
      />
      {date_type === EBotElementDateEditorType.CURRENT_DATE ? (
        <div>
          <label>Плюс (или минус) минут: </label>
          <Input
            value={modifier}
            type={"number"}
            autoFocus={true}
            isEnabled={true}
            onValueChange={(v) =>
              handleChange({ cond, date_type, modifier: v })
            }
          />
        </div>
      ) : (
        <div>
          <label>Конкретная дата (в миллисекундах): </label>
          <Input
            value={modifier}
            type={"number"}
            autoFocus={true}
            isEnabled={true}
            onValueChange={(v) =>
              handleChange({ cond, date_type, modifier: v })
            }
          />
        </div>
      )}
    </div>
  );
};

const ClientStatusEditor: React.FunctionComponent<{
  onValueChange: (newVal) => void;
  value: any;
}> = ({ onValueChange, value }) => {
  const val = value.val;

  const handleChange = ({ newStatus }) => {
    onValueChange({
      k: value.k,
      cond: EBotCondition.EQ,
      val: newStatus,
    });
  };

  return (
    <div className={style.editNodeInput}>
      <label>Равняется</label>
      <ComboboxInput
        options={CLIENT_STATUSES}
        value={val}
        onChange={(newVal) => handleChange({ newStatus: newVal })}
      />
    </div>
  );
};

const ClientSettingsEditor: React.FunctionComponent<{
  onValueChange: (newVal) => void;
  value: any;
}> = ({ onValueChange, value }) => {
  const cond = get(value, "cond", EBotCondition.GTE);

  const { date_type, modifier, var_unique_id, exists } = {
    date_type: get(
      value,
      "val.date_type",
      EBotElementDateEditorType.CURRENT_DATE,
    ),
    modifier: get(value, "val.modifier", 0),
    var_unique_id: get(value, "val.var_unique_id", ""),
    exists: get(value, "val.exists", true),
  };

  const handleChange = ({
    var_unique_id,
    cond,
    date_type,
    modifier,
    exists = true,
  }) => {
    onValueChange({
      k: value.k,
      cond: cond || EBotCondition.GTE,
      val: {
        exists,
        var_unique_id: var_unique_id,
        date_type: date_type || EBotElementDateEditorType.CURRENT_DATE,
        modifier: modifier || 0,
      },
    });
  };

  return (
    <div className={style.editNodeInput}>
      <div>
        <label>Ключ </label>
        <AsyncInput
          value={var_unique_id}
          entityType={EEntityType.BOT_VARIABLE}
          additionalFilters={{
            bot_script_id:
              root.botScriptItemController.entityController.item._id,
          }}
          valueField={"unique_id"}
          isMulti={false}
          onChange={(v) =>
            handleChange({ var_unique_id: v, cond, date_type, modifier })
          }
        />
      </div>
      <ComboboxInput
        options={BOT_ELEMENT_CONDITIONS}
        value={cond}
        onChange={(v) =>
          handleChange({ var_unique_id, cond: v, date_type, modifier })
        }
      />
      {cond === EBotCondition.EXISTS ? (
        <ComboboxInput
          options={BOT_COND_EXISTS}
          value={exists}
          onChange={(v) =>
            handleChange({
              var_unique_id,
              cond,
              date_type,
              modifier,
              exists: v as unknown as boolean,
            })
          }
        />
      ) : (
        <>
          <ComboboxInput
            options={BOT_DATE_TYPES}
            value={date_type}
            onChange={(v) =>
              handleChange({ var_unique_id, cond, date_type: v, modifier })
            }
          />
          {date_type === EBotElementDateEditorType.CURRENT_DATE ? (
            <div>
              <label>Плюс (или минус) минут: </label>
              <Input
                value={modifier}
                type={"number"}
                autoFocus={true}
                isEnabled={true}
                onValueChange={(v) =>
                  handleChange({ var_unique_id, cond, date_type, modifier: v })
                }
              />
            </div>
          ) : (
            <div>
              <label>Конкретная дата (в миллисекундах): </label>
              <Input
                value={modifier}
                type={"number"}
                autoFocus={true}
                isEnabled={true}
                onValueChange={(v) =>
                  handleChange({ var_unique_id, cond, date_type, modifier: v })
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const ValueTypedEditor: React.FunctionComponent<{
  onValueChange: (newVal) => void;
  value: any;
}> = ({ onValueChange, value }) => {
  const { k } = value ? value : { k: null };
  switch (k) {
    case EBotElementValueKey.VARIABLE:
      return (
        <ClientSettingsEditor onValueChange={onValueChange} value={value} />
      );
    case EBotElementValueKey.STATUS:
      return <ClientStatusEditor onValueChange={onValueChange} value={value} />;
    case EBotElementValueKey.PAYED_TO:
      return <DateValueEditor onValueChange={onValueChange} value={value} />;
    default:
      return null;
  }
};

const ValueEditor: React.FunctionComponent<{
  onValueChange: (newVal) => void;
  value: any;
}> = ({ onValueChange, value }) => {
  const { k, cond, val } = value ? value : { k: null, cond: null, val: null };

  return (
    <div className={style.editNodeInput}>
      <ComboboxInput
        options={BOT_ELEMENT_VALUE_KEYS}
        value={k}
        onChange={(v) => onValueChange({ k: v, cond, val })}
      />
      <ValueTypedEditor value={value} onValueChange={onValueChange} />
    </div>
  );
};

const inlineConditionRender = ({
  k,
  cond,
  val,
}: {
  k: EBotElementValueKey;
  cond: any;
  val: any;
}) => {
  if (!k) return "Не настроено";
  switch (k) {
    case EBotElementValueKey.PAYED_TO:
      if (!val || !val.date_type) return "Дата окончания подписки...";
      switch (val.date_type) {
        case EBotElementDateEditorType.CURRENT_DATE:
          const offsetString =
            (val.modifier > 0 ? ` плюс ` : " минус ") +
            getRelativeDuration(Math.abs(val.modifier) * 60000);
          return (
            <span>
              Дата окончания подписки {BOT_ELEMENT_CONDITIONS_KEYED[cond].label}{" "}
              {BOT_DATE_TYPES_KEYED[val.date_type].label} {offsetString}
            </span>
          );
        case EBotElementDateEditorType.SPECIFIC_DATE:
          return (
            <span>
              Дата окончания подписки {BOT_ELEMENT_CONDITIONS_KEYED[cond].label}{" "}
              конкретной даты {getDateString(val.modifier)}
            </span>
          );
      }
      break;
    case EBotElementValueKey.STATUS:
      return (
        <span>
          Статус равняется <ClientStatus status={val} />
        </span>
      );
    case EBotElementValueKey.VARIABLE:
      if (!val || !val.date_type || !val.var_unique_id)
        return "Условие по значению...";
      const variable =
        root.botScriptItemController.variablesTableController.findByQuery({
          unique_id: val.var_unique_id,
        });
      if (!variable) return "Условие по значению...";
      const varName = variable.title;

      if (cond === EBotCondition.EXISTS) {
        return (
          <span>
            Значение <span className={style.settingsLabel}>{varName}</span>{" "}
            {val.exists ? "задано" : "не задано"}
          </span>
        );
      }
      switch (val.date_type) {
        case EBotElementDateEditorType.CURRENT_DATE:
          const offsetString =
            (val.modifier > 0 ? ` плюс ` : " минус ") +
            getRelativeDuration(Math.abs(val.modifier) * 60000);
          return (
            <span>
              <span className={style.settingsLabel}>{varName}</span>{" "}
              {BOT_ELEMENT_CONDITIONS_KEYED[cond].label}{" "}
              {BOT_DATE_TYPES_KEYED[val.date_type].label} {offsetString}
            </span>
          );
        case EBotElementDateEditorType.SPECIFIC_DATE:
          return (
            <span>
              <span className={style.settingsLabel}>{varName}</span>{" "}
              {BOT_ELEMENT_CONDITIONS_KEYED[cond].label} конкретной даты{" "}
              {getDateString(val.modifier)}
            </span>
          );
      }
      break;
  }
  return `${k || ""} ${cond || ""} ${val || ""}`;
};

export const BotElementConditionForm: React.FunctionComponent<{
  controller: BotElementItemController;
}> = observer(({ controller }) => {
  const item = controller.entityController.item;
  if (!controller.elementConditionController) return null;
  return (
    <>
      <Form>
        <GridRow>
          <GridItem>
            <FormInput
              path={"element_data.title"}
              item={item}
              title={"Название"}
              onChange={controller.entityController.changeItem(
                "element_data.title",
              )}
            />
          </GridItem>
        </GridRow>
        <GridRow>
          <div className={style.ConditionEditowWrapper}>
            <h3>Редактор условий</h3>
            <NodeTreeEditor
              operators={[EConditionTerm.AND, EConditionTerm.OR]}
              value={controller.elementConditionController.treeController.getContent()}
              controller={controller.elementConditionController.treeController}
              customRenderValue={inlineConditionRender}
            />
          </div>
        </GridRow>
      </Form>
      <ElementTestingModal controller={controller.elementPhaseController} />
      <NodeEditModal
        controller={controller.elementConditionController.treeController}
        operators={[EConditionTerm.AND, EConditionTerm.OR]}
        ValueEditor={ValueEditor}
      />
    </>
  );
});
