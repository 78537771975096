import { RootController } from "../RootController";
import { makeAutoObservable } from "mobx";
import { NEW_ITEM_ID } from "../../../_shared/Constants/ItemConstants";
import { CommonEntityController } from "../_common/CommonEntityController";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { IBotElement } from "../../../_shared/Database/botElement";
import { generateBotText } from "../../../_shared/Util/messageGeneration/phraseGen";
import { EBotElementType } from "../../../_shared/_enums/EBotElementEnums";
import { TreeEditorController } from "../_common/TreeEditorController";
import { EConditionTerm } from "../../../_shared/_interfaces/nodeTreeEditor/INodeTreeEditor";
import { ConditionalController } from "../_common/ConditionalController";

const newItem: IBotElement = {
  _id: NEW_ITEM_ID,
  bot_script_id: "0",
  unique_id: "",
  connections: [],
  element_data: {
    title: "",
    text_constructor: {
      term: EConditionTerm.OR,
      content: [],
    },
  },
  type: EBotElementType.PHASE,
  x: 0,
  y: 0,
};

export class BotElementPhaseController {
  entityController: CommonEntityController<IBotElement>;
  phraseEditorController: TreeEditorController;
  isTestElementOpened: boolean = false;
  generatedText: string = "";

  constructor(entityController: CommonEntityController<IBotElement>) {
    this.entityController = entityController;
    this.phraseEditorController = new TreeEditorController(
      this.entityController,
      "element_data.text_constructor",
    );
    makeAutoObservable(this);
  }

  openTesting = () => {
    this.isTestElementOpened = true;
    this.generatePhrase();
  };

  closeTesting = () => {
    this.isTestElementOpened = false;
  };

  generatePhrase = () => {
    this.generatedText = generateBotText(
      {
        country: "Турция",
        city: "Анталья",
        userSettingsLinkId: "6419d73655d4b11773c67036",
      },
      this.phraseEditorController.getContent(),
    );
  };
}

export class BotElementResponseController {
  entityController: CommonEntityController<IBotElement>;
  conditionalController: ConditionalController;

  constructor(entityController: CommonEntityController<IBotElement>) {
    this.entityController = entityController;
    this.conditionalController = new ConditionalController(
      this.entityController,
      ["element_data", "core_content"],
    );
    makeAutoObservable(this);
  }
}

export class BotElementConditionController {
  entityController: CommonEntityController<IBotElement>;
  treeController: TreeEditorController;

  constructor(entityController: CommonEntityController<IBotElement>) {
    this.entityController = entityController;
    this.treeController = new TreeEditorController(this.entityController, [
      "element_data",
      "condition",
    ]);
    makeAutoObservable(this);
  }
}

export class BotElementItemController {
  root: RootController;

  entityController: CommonEntityController<IBotElement>;
  elementPhaseController: BotElementPhaseController | null = null;
  elementResponseController: BotElementResponseController | null = null;
  elementConditionController: BotElementConditionController | null = null;

  constructor(root: RootController) {
    this.root = root;
    this.entityController = new CommonEntityController<IBotElement>(
      EEntityType.BOT_ELEMENT,
      newItem,
    );
    makeAutoObservable(this);
  }

  initItem = async (botElementId) => {
    this.elementPhaseController = null;
    this.elementResponseController = null;
    this.elementConditionController = null;

    await this.entityController.initItem(botElementId);
    const item = this.entityController.item;
    switch (item.type as EBotElementType) {
      case EBotElementType.PHASE:
        this.elementPhaseController = new BotElementPhaseController(
          this.entityController,
        );
        return;
      case EBotElementType.RESPONSE_CHECK:
        this.elementResponseController = new BotElementResponseController(
          this.entityController,
        );
        return;
      case EBotElementType.CONDITION_CHECK:
        this.elementConditionController = new BotElementConditionController(
          this.entityController,
        );
        return;
    }
  };

  openCard = async (elementId, options?: any) => {
    await this.initItem(elementId);
    this.entityController.openCard(elementId, options).then();
  };

  closeCard = () => {
    this.entityController.closeCard();
  };

  openTesting = () => {
    if (this.elementPhaseController) {
      this.elementPhaseController.openTesting();
      return;
    }
    if (this.elementResponseController) {
      this.elementResponseController.conditionalController.openTesting();
      return;
    }
  };
}
