import React from "react";
import { observer } from "mobx-react-lite";
import { customSelectStyles } from "../../utils/select";
import { filter, find } from "lodash";
import Select from "react-select";
import { Simulate } from "react-dom/test-utils";
import change = Simulate.change;

interface IComboboxInput {
  options: any[];
  value: string | string[];
  isMulti?: boolean;
  isEnabled?: boolean;
  onChange?: (newValue: string | string[]) => void;
  onChangeRaw?: (obj: any) => void;
}

export const ComboboxInput: React.FunctionComponent<IComboboxInput> = observer(
  ({
    options,
    value,
    onChange,
    isEnabled = true,
    isMulti = false,
    onChangeRaw,
  }) => {
    let selectValue = null;
    if (value !== "undefined") {
      if (isMulti) {
        selectValue = filter(options, (i) => value.includes(i.value));
      } else {
        selectValue = find(options, { value: value });
      }
    }

    const handleInputChange = (changedItem) => {
      if (onChangeRaw) {
        onChangeRaw(changedItem);
      }
      if (!onChange) return;
      if (!changedItem && isMulti) {
        onChange([]);
        return;
      }

      if (isMulti) {
        onChange(changedItem.map((i) => i.value + ""));
        return;
      }

      if (!changedItem) {
        onChange(null);
      }

      onChange(changedItem.value);
    };

    return (
      <Select
        options={options}
        value={selectValue}
        isMulti={isMulti}
        isDisabled={!isEnabled}
        styles={customSelectStyles}
        menuPortalTarget={document.body}
        onChange={handleInputChange}
        placeholder={"Не выбрано"}
      />
    );
  },
);
