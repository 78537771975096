import {
  EConditionChangeCommand,
  EConditionTerm,
  EListType,
  ENodeType,
} from "../../../_shared/_interfaces/nodeTreeEditor/INodeTreeEditor";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { clone, filter, get, last, set } from "lodash";
import { makeToast } from "../../utils/toast";
import { CommonEntityController } from "./CommonEntityController";

export class TreeEditorController {
  currentEditingNode: { path: number[] } | null = null;
  entityController: CommonEntityController<any>;
  pathToTree: string;

  constructor(entityController: CommonEntityController<any>, pathToTree: any) {
    this.entityController = entityController;
    this.pathToTree = pathToTree;
    makeAutoObservable(this);
  }

  getContent = () => {
    if (!this.entityController.item) return null;
    return get(this.entityController.item, this.pathToTree);
  };

  setContent = (newContent) => {
    if (!this.entityController.item) return null;
    set(this.entityController.item, this.pathToTree, newContent);
  };

  getSelectedNode = (path: number[] | null, content = null) => {
    if (!path) return null;
    const conditionList = content ? content : this.getContent();
    if (path.length === 0) return conditionList;
    const nodeRealPath = "content." + path.join(".content.") + "";
    return get(conditionList, nodeRealPath);
  };

  setToSelectedNode = (params: { path: number[] } | null, value: any) => {
    if (!params) return null;
    const { path } = params;
    if (path.length === 0) return;
    const conditionList = this.getContent();
    const nodeRealPath = "content." + path.join(".content.") + "";
    return set(conditionList, nodeRealPath, value);
  };

  changeTypeSelectedNode = (
    params: { path: number[] } | null,
    newType: ENodeType,
  ) => {
    if (!params) return null;
    const { path } = params;
    if (path.length === 0) return;
    const conditionList = this.getContent();
    const nodeRealPath = "content." + path.join(".content.") + "";
    if (newType === ENodeType.NODE) {
      return set(conditionList, nodeRealPath, {
        term: EConditionTerm.OR,
        content: [],
      });
    }
    return set(conditionList, nodeRealPath, "[Измененное условие]");
  };

  deleteSelectedNode = (params: { path: number[] } | null) => {
    if (!params) return null;
    const { path } = params;
    if (path.length === 0) return;
    const conditionList = this.getContent();
    if (path.length === 1) {
      conditionList.content = conditionList.content.filter(
        (_, i) => i !== path[0],
      );
      return;
    }
    if (path.length > 1) {
      const lastIndex = path.pop();
      const parentNodePath = "content." + path.join(".content.") + "";
      let parentNode = get(conditionList, parentNodePath);
      parentNode.content = parentNode.content.filter((_, i) => i !== lastIndex);
    }
  };

  execCommand = (
    cmd: EConditionChangeCommand,
    path: number[],
    cmdValue?: any,
  ) => {
    runInAction(() => {
      const selectedNode = this.getSelectedNode(path, undefined);
      switch (cmd) {
        case EConditionChangeCommand.TOGGLE_OPEN_STATE:
          if (typeof selectedNode.isOpened === "undefined") {
            selectedNode.isOpened = false;
          } else {
            selectedNode.isOpened = !selectedNode.isOpened;
          }
          return;
        case EConditionChangeCommand.CHANGE_TITLE:
          selectedNode.title = cmdValue;
          return;
        case EConditionChangeCommand.CREATE:
          if (!selectedNode.content) {
            selectedNode.content = [];
          }
          selectedNode.content.unshift("[Новое условие]");
          return;
        case EConditionChangeCommand.OPEN_UPDATE_MODAL:
          if (path.length === 0) return;
          this.currentEditingNode = { path };
          return;
        case EConditionChangeCommand.CHANGE_TERM:
          selectedNode.term = cmdValue;
          return;
        case EConditionChangeCommand.CHANGE_OPTIONS:
          selectedNode.options = cmdValue;
          return;
        case EConditionChangeCommand.MAKE_DUMP:
          const jsonNode = toJS(selectedNode);
          const string = JSON.stringify(jsonNode);
          navigator.clipboard.writeText(string).then((r) => {
            makeToast("Dump has been copied to clipboard");
          });
          return;
        case EConditionChangeCommand.RESTORE_DUMP:
          const dump = prompt("Paste dump here");
          try {
            const parsedSearchCore = JSON.parse(dump);
            selectedNode.content = parsedSearchCore.content;
            selectedNode.term = parsedSearchCore.term;
            makeToast("Done!");
          } catch (e) {
            makeToast("Error while pasting search core");
          }
          return;
      }
    });
  };

  closeEditModal = () => {
    this.currentEditingNode = null;
  };

  onValueChange = (newValue: any) => {
    const selectedNode = this.getSelectedNode(this.currentEditingNode.path);
    if (!!selectedNode.term) return;
    this.setToSelectedNode(this.currentEditingNode, newValue || "");
  };

  onNodeDelete = () => {
    const selectedNode = this.getSelectedNode(this.currentEditingNode.path);
    if (typeof selectedNode === "undefined") return;
    this.deleteSelectedNode(this.currentEditingNode);
  };

  onNodeTypeChange = (newType: ENodeType) => {
    const selectedNode = this.getSelectedNode(this.currentEditingNode.path);
    if (typeof selectedNode === "undefined") return;
    this.changeTypeSelectedNode(this.currentEditingNode, newType);
  };

  onPositionChange = (
    dir: 1 | -1,
    mode: "nested" | "sections" = "sections",
  ) => {
    const currentContent = toJS(this.getContent());
    if (!this.currentEditingNode) return;
    const { path } = this.currentEditingNode;

    const currentIndex = last(path);
    const selectedNode = clone(this.getSelectedNode(path, currentContent));
    const parentNodePath = path.slice(0, path.length - 1);
    const grandparentPath = path.slice(0, path.length - 2);
    const parentNodeIndex = last(parentNodePath);

    const parentNode = this.getSelectedNode(parentNodePath, currentContent);
    const grandparent = this.getSelectedNode(grandparentPath, currentContent);

    if (
      ((currentIndex === 0 && dir === -1) ||
        (parentNode.content.length - 1 === currentIndex && dir === 1)) &&
      mode === "nested"
    ) {
      if (parentNodePath.length === 0) {
        return;
      }
      let newIndex = parentNodeIndex + (dir === 1 ? 1 : 0);
      newIndex = newIndex < 0 ? 0 : newIndex;
      // adding to grandparent
      grandparent.content.splice(newIndex, 0, selectedNode);
      // remove from parent

      parentNode.content = filter(parentNode.content, (v, i: number) => {
        return i !== currentIndex;
      });

      this.setContent(currentContent);
      this.currentEditingNode = {
        path: [...grandparentPath, newIndex],
      };
      return;
    }

    // cant move upper then first in 'sections' mode
    // cant move below last element in 'sections' mode
    if (
      (currentIndex === 0 && dir === -1) ||
      (parentNode.content.length - 1 === currentIndex && dir === 1)
    ) {
      if (mode === "sections") return;
    }

    const swapIndex = currentIndex + dir;
    const swapSibling = parentNode.content[swapIndex];
    // simply swap indexes if sibling is string

    if (typeof swapSibling === "string" || !swapSibling) {
      const tempNode = clone(parentNode.content[swapIndex]);
      parentNode.content[swapIndex] = selectedNode;
      parentNode.content[currentIndex] = tempNode;
      this.currentEditingNode = {
        path: [...parentNodePath, swapIndex],
      };
      this.setContent(currentContent);
      return;
    }

    // if sibling is not string, then nest current index indside them
    const currentNode = clone(parentNode.content[currentIndex]);

    parentNode.content = filter(parentNode.content as any, (v, i: number) => {
      return i !== currentIndex && !!v;
    });
    if (dir < 0) {
      this.currentEditingNode = {
        path: [...parentNodePath, swapIndex, swapSibling.content.length],
      };
      swapSibling.content = [...swapSibling.content, currentNode];
    } else {
      this.currentEditingNode = {
        path: [...parentNodePath, swapIndex - 1, 0],
      };
      swapSibling.content = [currentNode, ...swapSibling.content];
    }

    this.setContent(currentContent);
  };
}
