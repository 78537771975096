import React from "react";
import "./frontend/index.scss";
import reportWebVitals from "./frontend/reportWebVitals";
import ReactDOM from "react-dom/client";
import { ObservedRouter } from "./frontend/Router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { configure } from "mobx";

configure({
  enforceActions: "never",
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <>
    <ObservedRouter />
    <ToastContainer position="bottom-center" />
  </>,
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
