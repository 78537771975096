import React, { useEffect } from "react";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { root } from "../../../controllers/RootController";
import { observer } from "mobx-react-lite";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { ITableOptions, Table } from "../../../components/Table/Table";
import { CommonTableController } from "../../../controllers/_common/CommonTableController";
import {
  BotElement,
  ClientStatus,
} from "../../../components/ClientStatus/ClientStatus";
import { generateUserLink } from "../../../components/Util/Username";
import { getRelativeDate } from "../../../../_shared/Util/dates";
import { EClientStatus } from "../../../../_shared/_enums/EClientStatus";
import { PayedToLabel } from "../../../components/PayedToLabel/PayedToLabel";
import { UnreadMessages } from "../../../components/ChatMessenger/UnreadMessages";

const tableOptions = (
  tableController: CommonTableController,
): ITableOptions => {
  return {
    filters: {},
    columns: [
      {
        title: "@",
        field: "username",
        columnStyle: { width: "200px" },
        format: (val, item) => {
          const name = `${item.first_name || ""} ${item.last_name || ""}`;
          const subbed =
            name.length > 20 ? name.substring(0, 20) + "..." : name;
          if (item.is_chat_blocked) {
            return (
              <span>
                <UnreadMessages msgCount={item.unread_messages} />
                <del>
                  {subbed} {generateUserLink(item.username)}
                </del>
              </span>
            );
          }
          return (
            <span>
              <UnreadMessages msgCount={item.unread_messages} />
              <label>
                {subbed} {generateUserLink(item.username)}
              </label>
            </span>
          );
        },
      },
      {
        title: "Статус",
        field: "status",
        columnStyle: { width: "150px" },
        format: (val) => {
          const state = val ? val : EClientStatus.TO_BE_PROCESSED;
          return <ClientStatus status={state} />;
        },
      },
      {
        title: "Фаза",
        field: "bot_element",
        columnStyle: { width: "150px" },
        format: (val, item) => {
          return (
            <label>
              {item?.bot_control_script_id && item?.bot_control_script_id > 0
                ? "⚡"
                : null}
              <BotElement
                element={val}
                //needHuman={item.need_human_action}
              />
            </label>
          );
        },
      },
      {
        title: "Активность",
        field: "last_activity",
        columnStyle: { width: "150px" },
        format: (val) => {
          if (!val) return null;
          return getRelativeDate(val);
        },
      },
      {
        title: "Напоминание",
        field: "remind_at",
        columnStyle: { width: "150px" },
        format: (val) => {
          if (!val) return null;
          return getRelativeDate(val);
        },
      },
      {
        title: "Блок",
        field: "is_chat_blocked",
        format: (val) => {
          return !!val ? "Да" : "";
        },
      },
      {
        title: "Оплачено",
        field: "payed_to",
        format: (val: number) => {
          return <PayedToLabel val={val} onlyRelative={true} />;
        },
      },
      {
        title: "Сообщений",
        field: "messages_count",
        format: (val, item) => {
          if (!val) return "";
          return val + "";
        },
      },
      {
        title: "Чатов",
        field: "chat_ids",
        format: (val) => val?.length || 0,
      },
    ],
  };
};

export const UnreadsTable = observer(() => {
  useEffect(() => {
    root.unreadsController.unreadsCheck().then();
  }, []);
  const options = tableOptions(root.unreadsController.tableController);
  return (
    <PageWrapper header={"Непрочитанные оповещения"} state={EAsyncState.IDLE}>
      <Table
        options={options}
        onItemClick={(item) => root.clientItemController.openCard(item._id)}
        items={root.unreadsController.tableController.items || []}
      />
    </PageWrapper>
  );
});
