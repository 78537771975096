import React from "react";
import { observer } from "mobx-react-lite";
import { IBotElement } from "../../../_shared/Database/botElement";
import { DraggableHOC } from "./DraggableHOC";
import { BotEditorController } from "../../controllers/BotScript/BotEditorController";
import style from "./BotEditor.module.scss";
import { ReceiverHOC } from "./ReceiverHOC";
import cx from "classnames";
import { getElementTag } from "../../../_shared/Util/botElementUtils";
import {
  EBotElementSlotType,
  EBotElementType,
} from "../../../_shared/_enums/EBotElementEnums";
import { BOT_ELEMENT_SLOTS } from "../../../_shared/Constants/BotElementConstants";
import { get, map } from "lodash";
import { ConnectorHOC } from "./ConnectorHOC";
import { getRelativeDuration } from "../../../_shared/Util/dates";
import { EClientStatus } from "../../../_shared/_enums/EClientStatus";
import { ClientStatus } from "../ClientStatus/ClientStatus";
import { root } from "../../controllers/RootController";
import { trimToMax } from "../../../_shared/Util/text";

export const ElementTag: React.FunctionComponent<{ type: EBotElementType }> = ({
  type = EBotElementType.PHASE,
}) => {
  return (
    <div className={style.element_tag_wrapper}>
      <span className={style.text}>{getElementTag(type)}</span>
    </div>
  );
};

const Slots: React.FunctionComponent<{
  element: IBotElement;
  kind: EBotElementSlotType;
  controller: BotEditorController;
}> = ({ controller, element, kind }) => {
  const elementType = element.type || EBotElementType.PHASE;
  const slots = get(BOT_ELEMENT_SLOTS, [elementType, kind], []);
  if (!slots.length) return null;

  return (
    <div
      className={cx({
        [style.slot_stack]: true,
        [style[kind]]: true,
      })}
    >
      {map(BOT_ELEMENT_SLOTS[elementType][kind], (slot, slotId) => {
        const rectId = element.unique_id + "_" + kind + "_" + slotId;

        const canBeTarget =
          controller.drag.dragFromElementId &&
          controller.canSlotBeConnectionTarget(element.unique_id, slotId);

        return (
          <div
            className={style.slot_wrapper}
            key={element.unique_id + "_" + slotId}
            id={rectId}
          >
            {kind === EBotElementSlotType.INPUT ? (
              <ReceiverHOC
                id={element.unique_id}
                slotId={slotId}
                className={cx({
                  [style.slot_handler]: true,
                  [style.slot_can_be_connected]: canBeTarget,
                })}
                isActive={canBeTarget}
                selectConnectionTarget={controller.selectConnectionTarget}
                releaseConnectionTarget={controller.releaseConnectionTarget}
                dragMode={controller.drag.mode}
              >
                <span className={style.slot_dot} />
              </ReceiverHOC>
            ) : (
              <ConnectorHOC
                id={element.unique_id}
                element={element}
                startConnect={() =>
                  controller.startConnect(element.unique_id, slotId)
                }
                className={style.slot_handler}
              >
                <span className={style.slot_dot} />
              </ConnectorHOC>
            )}
          </div>
        );
      })}
    </div>
  );
};

const renderElementContent = (element: IBotElement) => {
  switch (element.type) {
    case EBotElementType.VARIABLE_CHANGE:
      const varUniqId: string = get(element, "element_data.var_unique_id", "");
      const variable =
        root.botScriptItemController.variablesTableController.findByQuery({
          unique_id: varUniqId,
        });
      return (
        <div className={style.phase_header}>
          {variable ? variable.title : "Выберите переменную"}
        </div>
      );
    case EBotElementType.STATUS_CHANGE:
      const status: EClientStatus = get(
        element,
        "element_data.new_status",
        EClientStatus.IN_PROCESS,
      );
      return (
        <div className={style.phase_header}>
          <ClientStatus status={status} />
        </div>
      );
    case EBotElementType.ALERT:
      const customMessage: string = get(element, "element_data.message", null);
      return (
        <div className={style.phase_header}>
          🚨 {customMessage ? trimToMax(customMessage, 15) : "Alert"}!
        </div>
      );
    case EBotElementType.TIMEOUT_CHECK:
      const minutes = get(element, "element_data.delay", 0);
      return (
        <div className={style.phase_header}>
          Wait {getRelativeDuration(minutes * 60000)}
        </div>
      );
    case EBotElementType.RESPONSE_CHECK:
    case EBotElementType.CONDITION_CHECK:
    case EBotElementType.PHASE:
      const title = get(element, "element_data.title", "");
      return <div className={style.phase_header}>{title}</div>;
  }
};

export const BotElement: React.FunctionComponent<{
  controller: BotEditorController;
  element: IBotElement;
}> = observer(({ element, controller }) => {
  const elementType = element.type ? element.type : EBotElementType.PHASE;
  const isMaster = !!(
    element.type === EBotElementType.PHASE &&
    get(element, "element_data.is_master", false)
  );

  return (
    <DraggableHOC
      className={cx({
        [style.element]: true,
        [style[elementType]]: true,
        [style.selected]: controller.getSelected().includes(element.unique_id),
        [style.isMaster]: isMaster,
      })}
      id={element.unique_id + ""}
      element={element}
      elementMouseDown={controller.elementMouseDown}
      deleteNode={controller.deleteElement}
    >
      <Slots
        element={element}
        kind={EBotElementSlotType.INPUT}
        controller={controller}
      />
      <div
        className={style.inner_wrapper}
        onDoubleClick={() => controller.openElementModal(element._id)}
      >
        <ElementTag type={elementType} />
        {renderElementContent(element)}
      </div>
      <Slots
        element={element}
        kind={EBotElementSlotType.OUTPUT}
        controller={controller}
      />
    </DraggableHOC>
  );
});
