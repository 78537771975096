import { PayedToLabel } from "../PayedToLabel/PayedToLabel";
import { Button } from "../Button/Button";
import { Input } from "../Input/Input";
import style from "./DateEditor.module.scss";
import React, { useState } from "react";
import cx from "classnames";
import { WindowEvents } from "../Util/Window";
import { DateTime } from "luxon";

export const DateEditor = ({
  value,
  onChange = (newVal: any) => {},
  isEnabled = true,
}) => {
  const [isOpened, setOpenState] = useState(false);
  const handleWindowClick = () => {
    setOpenState(false);
  };
  const handleEditorClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleTimeChange = (frame: string) => {
    if (frame === "today") {
      onChange(Date.now());
      return;
    }
    const newTime = DateTime.fromMillis(value)
      .plus({ [frame]: 1 })
      .toMillis();
    onChange(newTime);
  };

  return (
    <div className={style.dateEditorWrapper} onMouseUp={handleEditorClick}>
      <WindowEvents mouseup={handleWindowClick} />
      <div
        className={cx({ [style.dateString]: true, [style.selected]: isOpened })}
        onClick={() => setOpenState(!isOpened)}
      >
        <PayedToLabel val={value} />
      </div>
      {isOpened && isEnabled && (
        <div className={style.actionWrapper}>
          <Input
            value={value}
            type={"number"}
            onValueChange={onChange}
            isEnabled={true}
          />
          <Button text={"Сегодня"} onClick={() => handleTimeChange("today")} />
          <Button
            text={"Добавить день"}
            onClick={() => handleTimeChange("days")}
          />
          <Button
            text={"Добавить неделю"}
            onClick={() => handleTimeChange("weeks")}
          />
          <Button
            text={"Добавить месяц"}
            onClick={() => handleTimeChange("months")}
          />
        </div>
      )}
    </div>
  );
};
