import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { root } from "../../controllers/RootController";
import { ControlledTable, ITableOptions } from "../../components/Table/Table";
import { PageWrapper } from "../../components/PageWrapper/PageWrapper";
import { getDateStraight, getRelativeDate } from "../../../_shared/Util/dates";
import { UnreadMessages } from "../../components/ChatMessenger/UnreadMessages";
import { generateUserLink } from "../../components/Util/Username";
import {
  BotElement,
  ClientStatus,
} from "../../components/ClientStatus/ClientStatus";
import { EClientStatus } from "../../../_shared/_enums/EClientStatus";

const tableOptions: ITableOptions = {
  filters: {
    name: {
      title: "Поиск",
      makeQuery: (val) => {
        if (!val || val.trim() === "") {
          return {};
        }
        const regex = { $regex: val, $options: "i" };
        return {
          $and: [
            {
              $or: [
                { "bot.0.me.username": regex },
                { "bot.0.me.firstName": regex },
                { "bot.0.me.lastName": regex },
                { "client.0.username": regex },
                { "client.0.first_name": regex },
                { "client.0.last_name": regex },
              ],
            },
          ],
        };
      },
      type: "string",
    },
  },
  columns: [
    {
      title: " ",
      field: "unread_messages",
      columnStyle: { width: "30px" },
      format: (item) => {
        if (!item) return "";
        return (
          <span>
            <UnreadMessages msgCount={item} />
          </span>
        );
      },
    },
    {
      title: "Бот",
      field: "bot.0.me",
      columnStyle: { width: "150px" },
      format: (item) => {
        if (!item) return "";
        const name = `${item.firstName || ""} ${item.lastName || ""}`;
        const subbed = name.length > 20 ? name.substring(0, 20) + "..." : name;
        return (
          <span>
            <label>
              {subbed} {generateUserLink(item.username)}
            </label>
          </span>
        );
      },
    },
    {
      title: "Клиент",
      field: "client.0",
      columnStyle: { width: "150px" },
      format: (item) => {
        if (!item) return "";
        const name = `${item.first_name || ""} ${item.last_name || ""}`;
        const subbed = name.length > 20 ? name.substring(0, 20) + "..." : name;
        return (
          <span>
            <label>
              {subbed} {generateUserLink(item.username)}
            </label>
          </span>
        );
      },
    },
    {
      title: "Статус",
      field: "client.0.status",
      columnStyle: { width: "100px" },
      format: (val) => {
        const state = val ? val : EClientStatus.TO_BE_PROCESSED;
        return <ClientStatus status={state} />;
      },
    },
    {
      title: "Фаза",
      field: "bot_element",
      columnStyle: { width: "100px" },
      format: (element, item) => {
        if (!element) return null;
        return (
          <label>
            {item?.bot_control_script_id && item?.bot_control_script_id > 0
              ? "⚡"
              : null}
            <BotElement element={element} />
          </label>
        );
      },
    },
    {
      title: "Последнее действие бота",
      field: "last_bot_action_at",
      columnStyle: { width: "150px" },
      format: (val) => {
        if (!val) return null;
        return getDateStraight(val);
      },
    },
  ],
};

export const DialogsTable = observer(() => {
  let navigate = useNavigate();
  const controller = root.dialogsTableController;
  useEffect(() => {
    controller.loadTable().then();
  }, []);
  return (
    <PageWrapper header={"Диалоги"} state={EAsyncState.IDLE}>
      <ControlledTable
        getItemHref={(item) => `/dialog/${item._id}`}
        controller={controller}
        options={tableOptions}
      />
    </PageWrapper>
  );
});
