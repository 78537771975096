import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { root } from "../../../controllers/RootController";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { Loader } from "../../../components/Loader/Loader";
import { FormInput } from "../../../components/FormInput/FormInput";
import { observer } from "mobx-react-lite";
import { Form } from "../../../components/Form/Form";
import { GridItem } from "../../../components/Grid/GridItem";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { GridRow } from "../../../components/Grid/GridRow";

interface ICategoryItemProps {}

export const CategoryItem: React.FunctionComponent<ICategoryItemProps> =
  observer((props) => {
    let params = useParams();
    let navigate = useNavigate();
    const { categoryId } = params;
    const entityController = root.categoryItemController.entityController;
    useEffect(() => {
      entityController.initItem(categoryId).then();
    }, []);
    if (entityController.state !== EAsyncState.IDLE) return <Loader />;
    const item = entityController.item;
    return (
      <PageWrapper
        header={categoryId ? item.title : `Новая подкатегория`}
        onSave={async () => {
          const newItem = await entityController.saveItem();
          navigate(`/categories/${newItem._id}`);
        }}
        onDelete={
          categoryId
            ? async () => {
                const result = await entityController.deleteItem();
                if (result) {
                  navigate(`/categories`);
                }
              }
            : undefined
        }
        state={EAsyncState.IDLE}
      >
        <Form>
          <GridRow>
            <GridItem>
              <FormInput
                path={"title"}
                item={item}
                title={"Название"}
                onChange={entityController.changeItem("title")}
              />
            </GridItem>
            <GridItem>
              <FormInput
                path={"is_private"}
                item={item}
                type={"checkbox"}
                title={"Приватный"}
                onChange={entityController.changeItem("is_private")}
              />
            </GridItem>
          </GridRow>
        </Form>
      </PageWrapper>
    );
  });
