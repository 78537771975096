import { Modal } from "../Modal/Modal";
import React from "react";
import { GridRow } from "../Grid/GridRow";
import { Form } from "../Form/Form";
import { observer } from "mobx-react-lite";
import { PaymentController } from "../../controllers/Client/PaymentController";
import { EGridItemSize, GridItem } from "../Grid/GridItem";
import { FormInput } from "../FormInput/FormInput";
import { CURRENCIES } from "../../../_shared/Constants/Currencies";
import { ITransaction } from "../../../_shared/Database/transaction";
import { TextArea } from "../Input/Input";
import style from "./ClientForm.module.scss";
import { root } from "../../controllers/RootController";
import { Button } from "../Button/Button";
import { DateEditor } from "../DateEditor/DateEditor";
import { ComboboxInput } from "../ComboboxInput/ComboboxInput";
import { currencyController } from "../../controllers/_common/CurrencyController";

export const PaymentModal: React.FunctionComponent<{
  controller: PaymentController;
}> = observer(({ controller }) => {
  const isModalOpen = !!controller.isModalOpened;
  if (!isModalOpen) return null;
  const entityController = controller.entity;
  const item: ITransaction = entityController.item;
  return (
    <Modal
      header={"Новая оплата фида"}
      innerStyle={{ minWidth: 500 }}
      onClose={controller.closeModal}
      footer={
        <div className={style.footerActions}>
          <div></div>
          <div>
            {root.isRoot() && (
              <Button text={"Сохранить"} onClick={controller.registerPayment} />
            )}
          </div>
        </div>
      }
    >
      <Form>
        <GridRow>
          <GridItem>
            <FormInput
              path={"value"}
              item={item}
              title={"Сумма"}
              onChange={(val) => controller.changeValue(val)}
            />
          </GridItem>
          <GridItem>
            <label>Валюта</label>
            <ComboboxInput
              options={CURRENCIES}
              value={item.currency}
              onChange={controller.changeCurrency}
            />
          </GridItem>
          <GridItem>
            <label>Курс</label>
            <br />
            {currencyController.getRateToUsd(item.currency).toFixed(2)}
          </GridItem>
          <GridItem>
            <FormInput
              path={"usd_value"}
              item={item}
              title={"Сумма USD"}
              onChange={(val) => controller.changeValue(val, true)}
            />
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem size={EGridItemSize.full}>
            <label>Оплачено до</label>
            <DateEditor
              value={item.payment_target.payed_to}
              onChange={(newVal) =>
                entityController.changeItem("payment_target.payed_to")(newVal)
              }
            />
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem size={EGridItemSize.full}>
            <label></label>
            <FormInput
              path={"tx_id"}
              item={item}
              title={"Tx ID"}
              onChange={(val) => entityController.changeItem("tx_id")(val)}
            />
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem size={EGridItemSize.full}>
            <TextArea
              value={item.comment}
              onChange={(e) =>
                entityController.changeItem("comment")(e.target.value)
              }
            />
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem size={EGridItemSize.full}>
            <FormInput
              path={"isMessageNeeded"}
              item={controller}
              type={"checkbox"}
              title={"Отправить сообщение в фид об оплате?"}
              onChange={(val) => controller.changeIsMessageNeeded(val)}
            />
          </GridItem>
        </GridRow>
      </Form>
    </Modal>
  );
});
