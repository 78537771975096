import { observer } from "mobx-react-lite";
import { root } from "../../../controllers/RootController";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { Loader } from "../../../components/Loader/Loader";
import { EGridItemSize, GridItem } from "../../../components/Grid/GridItem";
import { Form } from "../../../components/Form/Form";
import { GridRow } from "../../../components/Grid/GridRow";
import {
  FormAsyncInput,
  FormInput,
} from "../../../components/FormInput/FormInput";
import { EEntityType } from "../../../../_shared/_enums/EEntityType";

interface ILeadSourceListItemProps {}

export const LeadSourceItem: React.FunctionComponent<ILeadSourceListItemProps> =
  observer((props) => {
    let params = useParams();
    let navigate = useNavigate();
    const { leadSourceId } = params;
    const controller = root.leadSourceItemController;
    const entityController = controller.entityController;
    useEffect(() => {
      entityController.initItem(leadSourceId);
    }, []);
    if (entityController.state !== EAsyncState.IDLE) return <Loader />;
    const item = entityController.item;

    return (
      <PageWrapper
        header={
          leadSourceId ? `Источник ${item?.title || ""}` : `Новый источник`
        }
        onSave={async () => {
          const newItem = await entityController.saveItem();
          navigate(`/leadSources/${newItem._id}`);
        }}
        onDelete={
          leadSourceId
            ? async () => {
                const result = await entityController.deleteItem();
                if (result) {
                  navigate(`/leadSources`);
                }
              }
            : undefined
        }
        state={EAsyncState.IDLE}
      >
        <Form>
          <GridRow>
            <GridItem>
              <FormInput
                path={"source_id"}
                item={item}
                title={"ID Чата"}
                isEnabled={false}
                onChange={entityController.changeItem("title")}
              />
            </GridItem>
            <GridItem>
              <FormInput
                path={"username"}
                item={item}
                title={"Short link"}
                isEnabled={false}
                onChange={entityController.changeItem("title")}
              />
            </GridItem>
            <GridItem>
              <FormInput
                path={"need_review"}
                item={item}
                type={"checkbox"}
                title={"Need rewiew"}
                onChange={entityController.changeItem("need_review")}
              />
            </GridItem>
          </GridRow>
          <GridRow>
            <GridItem>
              <FormInput
                path={"title"}
                item={item}
                title={"Название"}
                isEnabled={false}
                onChange={entityController.changeItem("title")}
              />
            </GridItem>
            <GridItem>
              <FormInput
                path={"source_type"}
                item={item}
                title={"Тип"}
                isEnabled={false}
                onChange={entityController.changeItem("title")}
              />
            </GridItem>
          </GridRow>
          <GridRow>
            <GridItem size={EGridItemSize.half}>
              <FormAsyncInput
                entityType={EEntityType.LOCATION}
                title={"Места"}
                path={"location_ids"}
                item={controller.entityController.item}
                isMulti={true}
                onChange={controller.entityController.changeItem(
                  "location_ids",
                )}
              />
            </GridItem>
          </GridRow>
        </Form>
      </PageWrapper>
    );
  });
