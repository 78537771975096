import { keyBy } from "lodash";

export enum EBotElementType {
  PHASE = "PHASE",
  RESPONSE_CHECK = "RESPONSE_CHECK",
  TIMEOUT_CHECK = "TIMEOUT_CHECK",
  STATUS_CHANGE = "STATUS_CHANGE",
  CONDITION_CHECK = "CONDITION_CHECK",
  VARIABLE_CHANGE = "VARIABLE_CHANGE",
  ALERT = "ALERT",
}

export enum EBotElementSlotType {
  INPUT = "INPUT",
  OUTPUT = "OUTPUT",
}

export enum EBotCondition {
  EXISTS = "exists",
  EQ = "eq",
  NEQ = "neq",
  GTE = "gte",
  LTE = "lte",
}

export const BOT_ELEMENT_CONDITIONS = [
  { label: "задан", value: EBotCondition.EXISTS },
  { label: "равно", value: EBotCondition.EQ },
  { label: "не равно", value: EBotCondition.NEQ },
  { label: "больше", value: EBotCondition.GTE },
  { label: "меньше", value: EBotCondition.LTE },
];
export const BOT_ELEMENT_CONDITIONS_KEYED = keyBy(
  BOT_ELEMENT_CONDITIONS,
  "value",
);

export enum EBotElementDateEditorType {
  SPECIFIC_DATE = "specific_date",
  CURRENT_DATE = "current_date",
}

export const BOT_DATE_TYPES = [
  { label: "Конкретная дата", value: EBotElementDateEditorType.SPECIFIC_DATE },
  { label: "Текущая дата", value: EBotElementDateEditorType.CURRENT_DATE },
];

export const BOT_COND_EXISTS = [
  { label: "Да", value: true },
  { label: "Нет", value: false },
];

export const BOT_DATE_TYPES_KEYED = keyBy(BOT_DATE_TYPES, "value");

export enum EBotElementValueKey {
  STATUS = "status",
  PAYED_TO = "payed_to",
  VARIABLE = "variable",
}

export const BOT_ELEMENT_VALUE_KEYS = [
  { label: "Не выбрано", value: null },
  { label: "Статус", value: EBotElementValueKey.STATUS },
  { label: "Оплачено до", value: EBotElementValueKey.PAYED_TO },
  { label: "Значение", value: EBotElementValueKey.VARIABLE },
];

export enum EPasteMode {
  OVERRIDE = "override",
  DUPLICATE = "duplicate",
  DESTROY = "destroy",
}

export const VALUE_KEYS_KEYED = keyBy(BOT_ELEMENT_VALUE_KEYS, "value");
