import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { CommonTableController } from "../../controllers/_common/CommonTableController";
import { root } from "../../controllers/RootController";
import { filter, get } from "lodash";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { trimText } from "../../../_shared/Util/text";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { AsyncInput } from "../../components/AsyncInput/AsyncInput";
import { EButtonType, Button } from "../../components/Button/Button";
import { UnreadMessages } from "../../components/ChatMessenger/UnreadMessages";
import { ComboboxInput } from "../../components/ComboboxInput/ComboboxInput";
import { Input } from "../../components/Input/Input";
import { PageWrapper } from "../../components/PageWrapper/PageWrapper";
import { ITableOptions, ControlledTable } from "../../components/Table/Table";
import { generateUserLink } from "../../components/Util/Username";

const { DateTime } = require("luxon");

const tableOptions = (
  tableController: CommonTableController,
): ITableOptions => {
  return {
    filters: {
      name: {
        title: "Поиск",
        customRender(value, onChange) {
          return (
            <Input
              title={"Поиск"}
              type={"string"}
              value={value}
              onValueChange={onChange}
              isEnabled={true}
            />
          );
        },
        makeQuery: (val) => {
          if (!val || val.trim() === "") {
            return {};
          }
          const regex = { $regex: val, $options: "i" };
          return {
            $and: [
              {
                $or: [
                  { username: regex },
                  { first_name: regex },
                  { last_name: regex },
                  { tg_user_id: regex },
                  { comment: regex },
                ],
              },
            ],
          };
        },
        type: "string",
      },
      automation_scheduled: {
        title: "К автоматизации",
        makeQuery: (val) => {
          if (!val) {
            return { automation_scheduled_at: null };
          }
          return {
            automation_scheduled_at: { $ne: null },
          };
        },
        type: "custom",
        customRender: (value, onChange) => {
          const options = [
            { label: "Не запланированные", value: undefined },
            { label: "Запланированные", value: "planned" },
          ];
          return (
            <ComboboxInput
              options={options}
              value={value || undefined}
              onChange={(newVal) => onChange(newVal)}
            />
          );
        },
      },
      locationSearch: {
        title: "Место",
        makeQuery: (country) => {
          if (!country) return {};
          return { location_ids: { $in: [country._id] } };
        },
        type: "custom",
        customRender: (countryId, onChange) => {
          return (
            <AsyncInput
              value={countryId ? countryId._id : null}
              entityType={EEntityType.LOCATION}
              onChangeRaw={onChange}
            />
          );
        },
      },
      scriptSearch: {
        title: "Скрипт",
        makeQuery: (script) => {
          if (!script) return {};
          return { bot_control_script_id: { $in: [script._id] } };
        },
        type: "custom",
        customRender: (script, onChange) => {
          return (
            <AsyncInput
              value={script ? script._id : null}
              entityType={EEntityType.BOT_SCRIPT}
              onChangeRaw={onChange}
            />
          );
        },
      },
      enalbeBot: {
        title: "Рассылка",
        type: "custom",
        customRender: () => {
          return (
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "5px" }}>
                <Button
                  style={{ whiteSpace: "nowrap" }}
                  text={"⚡ Аппрувнуть"}
                  styling={EButtonType.DEFAULT}
                  onClick={() =>
                    root.schedullingController.approveSpam(tableController)
                  }
                />
              </div>
              <div>
                <Button
                  style={{ whiteSpace: "nowrap" }}
                  text={"Удалить клиентов"}
                  styling={EButtonType.DEFAULT}
                  onClick={() =>
                    root.schedullingController.massDelete(tableController)
                  }
                />
              </div>
            </div>
          );
        },
      },
    },
    columns: [
      {
        title: "@",
        field: "username",
        columnStyle: { width: "200px" },
        format: (val, item) => {
          const name = `${item.first_name || ""} ${item.last_name || ""}`;
          const subbed = trimText(name, 20);
          if (item.is_chat_blocked) {
            return (
              <span>
                <UnreadMessages msgCount={item.unread_messages} />
                <del>
                  {subbed} {generateUserLink(item.username)}
                </del>
              </span>
            );
          }
          return (
            <span>
              <UnreadMessages msgCount={item.unread_messages} />
              <label>
                {subbed} {generateUserLink(item.username)}
              </label>
            </span>
          );
        },
      },
      {
        title: "Скрипт бота",
        field: "bot_script",
        columnStyle: { width: "120px" },
        format: (val, item) => {
          return <label>{val ? val.title : null}</label>;
        },
      },
      {
        title: "Chat",
        field: "messages",
        columnStyle: { width: "50px" },
        format: (messages) => {
          if (!messages) {
            return "";
          }
          const fullText = get(Object.values(messages), "0.chatUsername", "");
          const text = trimText(fullText, 10);
          return <span title={fullText}>{text}</span>;
        },
      },
      {
        title: "Текст",
        field: "messages",
        format: (messages) => {
          if (!messages) {
            return "";
          }
          const fullText = get(Object.values(messages), "0.text", "");
          const text = trimText(fullText, 100);
          return <span title={fullText}>{text}</span>;
        },
      },
      {
        title: "Места",
        field: "location_ids",
        format: (val: string[], item: any) => {
          const location_ids = filter(
            root.locationTableController.items,
            (loc) => {
              if (!val) return false;
              return val.includes(loc._id + "");
            },
          );
          return location_ids
            .map((loc) => {
              return loc.title;
            })
            .join(" ,");
        },
      },
    ],
  };
};

export const SpamScheduleTable = observer(() => {
  useEffect(() => {
    root.locationTableController
      .loadTable()
      .then(
        root.schedullingController.schedullingClientTableController.loadTable,
      );
  }, []);
  return (
    <PageWrapper header={"Управление рассылками"} state={EAsyncState.IDLE}>
      <ControlledTable
        controller={root.schedullingController.schedullingClientTableController}
        options={tableOptions(
          root.schedullingController.schedullingClientTableController,
        )}
        getItemHref={(item) => `/clients/${item._id}`}
        onItemClick={(item) => root.clientItemController.openCard(item._id)}
      />
    </PageWrapper>
  );
});
