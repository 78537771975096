import { EBotElementType } from "../_enums/EBotElementEnums";
import { IBotElement } from "../Database/botElement";
import { EConditionTerm } from "../_interfaces/nodeTreeEditor/INodeTreeEditor";
import { get } from "lodash";
import { EClientStatus } from "../_enums/EClientStatus";
import { CLIENT_STATUS_KEYED } from "../Constants/ClientStatuses";

export const getInitialElementDataFromType = (type: EBotElementType) => {
  switch (type) {
    case EBotElementType.CONDITION_CHECK:
      return {
        title: getElementInitTitle(type),
        condition: { term: EConditionTerm.AND, content: [] },
      };
    case EBotElementType.STATUS_CHANGE:
      return {
        new_status: EClientStatus.IGNORE,
      };
    case EBotElementType.PHASE:
      return {
        title: getElementInitTitle(type),
        text_constructor: {
          term: EConditionTerm.AND,
          content: [],
        },
      };
    case EBotElementType.RESPONSE_CHECK:
      return {
        title: getElementInitTitle(type),
        core_content: {
          max_links: 2,
          max_length: 800,
          whitelist: {
            term: EConditionTerm.OR,
            content: [],
          },
          blacklist: {
            term: EConditionTerm.OR,
            content: [],
          },
        },
      };
    case EBotElementType.TIMEOUT_CHECK:
      return {
        delay: 30,
      };
  }
};

export const getElementTag = (type: EBotElementType) => {
  switch (type) {
    case EBotElementType.PHASE:
      return "фаза";
    case EBotElementType.RESPONSE_CHECK:
      return "ответ";
    case EBotElementType.TIMEOUT_CHECK:
      return "ожидание";
    case EBotElementType.STATUS_CHANGE:
      return "смена статуса";
    case EBotElementType.CONDITION_CHECK:
      return "условие";
    case EBotElementType.VARIABLE_CHANGE:
      return "изменение переменной";
    case EBotElementType.ALERT:
      return "алерт";
  }
};

export const getBotElementTitle = (element: IBotElement) => {
  let output = getElementTag(element.type);
  const title = get(element, "element_data.title", null);
  if (title) {
    output = getElementTag(element.type) + " " + title;
  }
  if (element.type === EBotElementType.TIMEOUT_CHECK) {
    output =
      getElementTag(element.type) + " " + element.element_data.delay + " m";
  }
  if (element.type === EBotElementType.STATUS_CHANGE) {
    output =
      "Смена статуса на " +
      CLIENT_STATUS_KEYED[element.element_data.new_status].label;
  }
  if (element.type === EBotElementType.VARIABLE_CHANGE) {
    output = getElementTag(element.type);
  }
  if (element.type === EBotElementType.ALERT) {
    const customMessage = element.element_data?.message || null;
    output = customMessage ? "🚨 " + customMessage : output;
  }
  return output.charAt(0).toUpperCase() + output.slice(1);
};

export const getElementInitTitle = (type: EBotElementType) => {
  switch (type) {
    case EBotElementType.PHASE:
      return "Новая " + getElementTag(type);
    case EBotElementType.RESPONSE_CHECK:
      return "Новый " + getElementTag(type);
    case EBotElementType.TIMEOUT_CHECK:
      return "Новое " + getElementTag(type);
    case EBotElementType.CONDITION_CHECK:
      return "Новое " + getElementTag(type);
  }
};
