export function fixCoordsToZoom(coords, zoom) {
  return {
    x: coords.x / zoom,
    y: coords.y / zoom,
  };
}

export function distance(p1, p2) {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  return Math.sqrt(dx * dx + dy * dy);
}

export function translatePointToCanvas(coords, matrixData) {
  const zoom = matrixData[0];
  const pan = { x: matrixData[4], y: matrixData[5] };
  const pannedCoords = { x: coords.x - pan.x, y: coords.y - pan.y };
  return fixCoordsToZoom(pannedCoords, zoom);
}

export function relativePoint({ x, y }, offset) {
  return { x: x - offset.x, y: y - offset.y };
}

export function isPointInsideAABB(p, box) {
  const [minX, maxX] =
    box.a.x > box.b.x ? [box.b.x, box.a.x] : [box.a.x, box.b.x];
  const [minY, maxY] =
    box.a.y > box.b.y ? [box.b.y, box.a.y] : [box.a.y, box.b.y];

  return p.x >= minX && p.x <= maxX && p.y >= minY && p.y <= maxY;
}
