import { EClientStatus } from "../../../_shared/_enums/EClientStatus";
import { CLIENT_STATUS_KEYED } from "../../../_shared/Constants/ClientStatuses";
import style from "./ClientStatus.module.scss";
import cx from "classnames";
import { IBotElement } from "../../../_shared/Database/botElement";
import { EBotElementType } from "../../../_shared/_enums/EBotElementEnums";

export const ClientStatus: React.FC<{ status: EClientStatus }> = ({
  status,
}) => {
  if (!status) return null;
  if (!CLIENT_STATUS_KEYED[status]) return null;
  return (
    <span
      className={cx({
        [style.clientStatusWrapper]: true,
        [style[status]]: true,
      })}
    >
      {CLIENT_STATUS_KEYED[status].label}
    </span>
  );
};

export const BotElement: React.FC<{ element: IBotElement }> = ({ element }) => {
  if (!element) return null;
  if (element.type !== EBotElementType.PHASE) return null;
  return (
    <span
      className={cx({
        [style.clientStatusWrapper]: true,
      })}
    >
      {element?.element_data.title}
    </span>
  );
};
