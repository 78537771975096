import style from "../BotElementItem.module.scss";
import { observer } from "mobx-react-lite";
import {
  FormAsyncInput,
  FormInput,
} from "../../../../components/FormInput/FormInput";
import { EGridItemSize, GridItem } from "../../../../components/Grid/GridItem";
import { Form } from "../../../../components/Form/Form";
import { EConditionTerm } from "../../../../../_shared/_interfaces/nodeTreeEditor/INodeTreeEditor";
import { NodeEditModal } from "../../../../components/NodeTreeEditor/NodeEditModal";
import { TestingModal } from "../../../searchCore/SearchCoreItem/TestingModal";
import { BotElementItemController } from "../../../../controllers/BotScript/BotElementItemController";
import { GridRow } from "../../../../components/Grid/GridRow";
import { NodeTreeEditor } from "../../../../components/NodeTreeEditor/NodeTreeEditor";

export const BotElementResponseForm: React.FunctionComponent<{
  controller: BotElementItemController;
}> = observer(({ controller }) => {
  const item = controller.entityController.item;
  if (!controller.elementResponseController) return null;
  return (
    <>
      <Form>
        <GridRow>
          <GridItem>
            <FormInput
              path={"element_data.title"}
              item={item}
              title={"Название"}
              onChange={controller.entityController.changeItem(
                "element_data.title",
              )}
            />
          </GridItem>
          <GridItem>
            <FormInput
              path={"element_data.is_single_use"}
              item={item}
              type="checkbox"
              isEnabled={true}
              title={"Одноразовый"}
              onChange={controller.entityController.changeItem(
                "element_data.is_single_use",
              )}
            />
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem size={EGridItemSize.half}>
            <FormInput
              path={"element_data.ai_prompt"}
              item={item}
              title={"Промпт для AI"}
              onChange={controller.entityController.changeItem(
                "element_data.ai_prompt",
              )}
            />
          </GridItem>
        </GridRow>
        <GridRow>
          <div className={style.ConditionEditowWrapper}>
            <h3>Whitelist</h3>
            <NodeTreeEditor
              value={controller.elementResponseController.conditionalController.whiteListController.getContent()}
              controller={
                controller.elementResponseController.conditionalController
                  .whiteListController
              }
            />
          </div>
          <div className={style.ConditionEditowWrapper}>
            <h3>Blacklist</h3>
            <NodeTreeEditor
              value={controller.elementResponseController.conditionalController.blackListController.getContent()}
              controller={
                controller.elementResponseController.conditionalController
                  .blackListController
              }
            />
          </div>
        </GridRow>
      </Form>
      <TestingModal
        controller={controller.elementResponseController.conditionalController}
      />
      <NodeEditModal
        controller={
          controller.elementResponseController.conditionalController
            .blackListController
        }
        operators={[EConditionTerm.AND, EConditionTerm.OR]}
      />
      <NodeEditModal
        controller={
          controller.elementResponseController.conditionalController
            .whiteListController
        }
        operators={[EConditionTerm.AND, EConditionTerm.OR]}
      />
    </>
  );
});
