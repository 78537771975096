import { RootController } from "../RootController";
import { makeAutoObservable } from "mobx";
import { NEW_ITEM_ID } from "../../../_shared/Constants/ItemConstants";
import { CommonEntityController } from "../_common/CommonEntityController";
import { ConditionalController } from "../_common/ConditionalController";
import { EEntityType } from "../../../_shared/_enums/EEntityType";
import { apiController } from "../ApiController";
import { ITargetList } from "../../../_shared/Database/targetList";
import { EAsyncState } from "../../../_shared/_enums/EAsyncState";
import { makeToast } from "../../utils/toast";
import { CommonTableController } from "../_common/CommonTableController";
import { ECrudMethods } from "../../../_shared/_enums/ECrudMethods";
import { prompter } from "../_common/PromptController";

const newItem: ITargetList = {
  _id: NEW_ITEM_ID,
  title: "",
  creation_date: Date.now(),
  lead_count: 0,
};

export class TargetListItemController {
  root: RootController;
  conditionalController: ConditionalController;
  entityController: CommonEntityController<ITargetList>;
  targetClientTableController: CommonTableController;
  addingDumps: {
    isOpened: boolean;
    files: any[];
    searchCoreId: null | string;
    status: EAsyncState;
  } = {
    isOpened: false,
    files: [],
    searchCoreId: null,
    status: EAsyncState.IDLE,
  };
  searchCores: any = {};

  constructor(root: RootController) {
    this.root = root;
    this.entityController = new CommonEntityController<ITargetList>(
      EEntityType.TARGET_LIST,
      newItem,
    );
    this.targetClientTableController = new CommonTableController(
      EEntityType.CLIENT,
    );
    makeAutoObservable(this);
  }

  initItem = async (targetListId: string) => {
    await this.entityController.initItem(targetListId);
    await this.loadSearchCores();
    if (targetListId) {
      this.targetClientTableController = new CommonTableController(
        EEntityType.CLIENT,
        {
          autoFetch: true,
          filter: { target_list_ids: { $in: [targetListId] } },
        },
      );
      await this.targetClientTableController.loadTable();
    }
  };

  loadSearchCores = async () => {
    apiController[EEntityType.SEARCH_CORE]
      [ECrudMethods.LIST]({}, 0, 10000)
      .then((resp) => {
        this.searchCores = resp.items;
      });
  };

  changeSearchCore = (searchCoreId) => {
    this.addingDumps.searchCoreId = searchCoreId ? searchCoreId : null;
  };

  openAddingDumps = () => {
    this.addingDumps = {
      isOpened: true,
      files: [],
      searchCoreId: null,
      status: EAsyncState.IDLE,
    };
  };

  closeAddingDumps = () => {
    this.addingDumps = {
      isOpened: false,
      files: [],
      searchCoreId: null,
      status: EAsyncState.IDLE,
    };
  };

  uploadDumps = (files) => {
    this.addingDumps.files = files;
  };

  startProcessingDump = async (targetListId: string) => {
    if (!this.addingDumps.searchCoreId) {
      await prompter.alert("Выберите ядро для поиска сообщений");
      return;
    }
    if (this.addingDumps.files.length === 0) {
      await prompter.alert("Добавьте файлы для загрузки");
      return;
    }
    try {
      this.addingDumps.status = EAsyncState.LOADING;
      const res = await apiController.targeting.processChatDumps(
        this.addingDumps.files,
        this.addingDumps.searchCoreId,
        targetListId,
      );
      this.addingDumps.status = EAsyncState.IDLE;
      this.closeAddingDumps();
      if (res.message) {
        makeToast("Ошибка! " + res.message);
        return;
      }
      makeToast("Загрузка прошла успешно, лиды обрабатываются...");
    } catch (e) {
      this.addingDumps.status = EAsyncState.FAILURE;
    }
  };
}
