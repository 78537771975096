import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { root } from "../../../controllers/RootController";
import {
  ControlledTable,
  ITableOptions,
} from "../../../components/Table/Table";
import { PageWrapper } from "../../../components/PageWrapper/PageWrapper";
import { PayedToLabel } from "../../../components/PayedToLabel/PayedToLabel";
import { EAsyncState } from "../../../../_shared/_enums/EAsyncState";
import { generateUserLink } from "../../../components/Util/Username";
import { getDateStraight } from "../../../../_shared/Util/dates";

const tableOptions: ITableOptions = {
  filters: {
    sender: {
      title: "Sender",
      makeQuery: (val) => {
        if (!val || val.trim() === "") {
          return {};
        }
        const regex = { $regex: val, $options: "i" };
        return {
          $and: [
            {
              $or: [
                { "client.0.username": regex },
                { "client.0.first_name": regex },
                { "client.0.last_name": regex },
              ],
            },
          ],
        };
      },
      type: "string",
    },
    amount: {
      title: "От суммы",
      makeQuery: (val) => {
        if (val.trim() === "" || typeof val !== "string") {
          return {};
        }
        return {
          $and: [
            {
              $or: [{ value: { $gte: parseInt(val) } }],
            },
          ],
        };
      },
      type: "string",
    },
    txId: {
      title: "Tx ID",
      makeQuery: (val) => {
        if (!val || val.trim() === "") {
          return {};
        }
        return {
          $and: [
            {
              $or: [{ tx_id: { $eq: val.trim() } }],
            },
          ],
        };
      },
      type: "string",
    },
  },
  columns: [
    {
      title: "Клиент",
      field: "client.0",
      columnStyle: { width: "150px" },
      format: (item) => {
        if (!item) return null;
        const name = `${item.first_name || item.firstName || ""} ${
          item.last_name || item.lastName || ""
        }`;
        const subbed = name.length > 20 ? name.substring(0, 20) + "..." : name;
        return (
          <span>
            <label>
              {subbed} {generateUserLink(item.username)}
            </label>
          </span>
        );
      },
    },
    {
      title: "Сумма",
      field: "currency",
      format: (val: number, item) => {
        return item.value + " " + item.currency;
      },
    },
    {
      title: "Дата создания",
      field: "created_at",
      format: (val: number) => {
        return getDateStraight(val);
      },
    },
    {
      title: "Tx ID",
      field: "tx_id",
    },
  ],
};

export const TransactionTable = observer(() => {
  useEffect(() => {
    root.transactionTableController.loadTable().then();
  }, []);
  return (
    <PageWrapper header={"Транзакции"} state={EAsyncState.IDLE}>
      <ControlledTable
        controller={root.transactionTableController}
        options={tableOptions}
        getItemHref={(item) => `/transactions/${item._id}`}
      />
    </PageWrapper>
  );
});
