import React from "react";
import style from "./NodeTreeEditor.module.scss";
import { observer } from "mobx-react-lite";
import { get, map } from "lodash";

import cx from "classnames";
import { Toggle } from "../Toggle/Toggle";
import {
  defaultConditionOperators,
  TERM_ITEMS_KEYED,
} from "../../../_shared/Constants/ConditionConstsnts";
import { Button } from "../Button/Button";
import { TreeEditorController } from "../../controllers/_common/TreeEditorController";
import {
  EConditionChangeCommand,
  EConditionTerm,
  IConditionNode,
} from "../../../_shared/_interfaces/nodeTreeEditor/INodeTreeEditor";
import { replaceAll } from "../../../_shared/Util/text";

export interface INodeTreeEditorProps {
  controller: TreeEditorController;
  value: IConditionNode;
  path?: number[];
  isRoot?: boolean;
  operators?: EConditionTerm[];
  isOpened?: boolean;
  customRenderValue?: any;
}

export const NodeTreeEditor: React.FunctionComponent<INodeTreeEditorProps> =
  observer(
    ({
      operators = defaultConditionOperators,
      value,
      path = [],
      isRoot = true,
      controller,
      customRenderValue,
    }) => {
      const onCommand = controller.execCommand;

      const { term, content, options, title, isOpened = true } = value;
      const selectedPath = controller.currentEditingNode?.path;

      const handleCreateNewNode = (e: any) => {
        e.stopPropagation();
        onCommand(EConditionChangeCommand.CREATE, path);
      };

      const isSelected = path.join(".") === (selectedPath || [-1]).join(".");

      const handleChangeNode = (path: number[], e: any) => {
        e.stopPropagation();
        e.preventDefault();
        onCommand(EConditionChangeCommand.OPEN_UPDATE_MODAL, path);
      };

      const handleChangeTerm = (newTerm: string) => {
        onCommand(
          EConditionChangeCommand.CHANGE_TERM,
          path,
          newTerm as EConditionTerm,
        );
      };

      const handleExpand = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        onCommand(EConditionChangeCommand.TOGGLE_OPEN_STATE, path);
      };

      const handleMakeDump = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        onCommand(EConditionChangeCommand.MAKE_DUMP, path);
      };

      const handleRestoreDump = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        onCommand(EConditionChangeCommand.RESTORE_DUMP, path);
      };

      if ((customRenderValue && !value.term) || typeof value === "string") {
        return (
          <span
            key={path.join(".")}
            className={cx({
              [style.TextNode]: true,
              [style.isSelected]: isSelected,
            })}
            onClick={(e) => handleChangeNode(path, e)}
          >
            {typeof value === "string"
              ? replaceAll(value, " ", "␣") || " "
              : customRenderValue(value)}
          </span>
        );
      }

      const hasTitle = !!title;
      return (
        <div className={cx({ [style.NodeTreeEditorWrapper]: true })}>
          {hasTitle && <div className={style.nodeTitle}>{title}</div>}
          <div
            className={cx({
              [style.NodeTreeEditor]: true,
              [style.isSelected]: isSelected,
              [style.hasTitle]: hasTitle,
            })}
            onClick={(e) => handleChangeNode(path, e)}
          >
            <div
              className={cx({
                [style.ConditionNodeHeader]: true,
                [style.isExpanded]: isOpened,
              })}
            >
              <div className={style.leftNodeToolbar}>
                <div className={style.ExpanderArrow} onClick={handleExpand}>
                  ⇢
                </div>
                {isOpened && (
                  <div>
                    <Toggle
                      variants={operators.map((o) => TERM_ITEMS_KEYED[o])}
                      onSelect={handleChangeTerm}
                      selected={term}
                    />
                  </div>
                )}
                {term === EConditionTerm.SEQ &&
                  options &&
                  get(options, "max_distance", 75) !== 75 && (
                    <span className={style.seqOptions}>
                      = {options.max_distance}
                    </span>
                  )}
                <div
                  className={style.AddNewNodeButton}
                  onClick={handleCreateNewNode}
                >
                  +
                </div>
              </div>
              {isRoot && (
                <div className={style.rightNodeToolbar}>
                  <Button text={"Copy"} onClick={handleMakeDump} />
                  <Button text={"Paste"} onClick={handleRestoreDump} />
                </div>
              )}
            </div>
            {isOpened ? (
              <div className={style.ContentListWrapper}>
                {map(content, (childNode, index) => {
                  const nestedPath = [...path, index];
                  return (
                    <NodeTreeEditor
                      controller={controller}
                      operators={operators}
                      key={nestedPath.join(".")}
                      path={nestedPath}
                      value={childNode as any}
                      isRoot={false}
                      customRenderValue={customRenderValue}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      );
    },
  );
